import React, { Suspense } from "react";
import Header from "@/components/Header/Header";
import Routes from "@/routing/Routes";
import { TrackingContextProvider } from "@/context/TrackingContext";
import { UsersnapProvider } from "@/context/UserSnapContext";
import FaviconManager from "@/components/FaviconManager";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyle from "@/utils/theme/GlobalStyle";
import theme from "@/utils/theme/theme";
import { ThemeProvider } from "styled-components";

import { GlobalFontsNunito, ToastContextProvider } from "@emonvia/web-components";
import "@/assets/fontline.css";
import "@/utils/i18n";
import { SerialNumberProvider } from "@/context/SerialNumberContext";
import { AppRerenderer } from "@/components/withRerenderApp";
import { OnboardingProvider } from "@/context/OnboardingContext";
import { useBetterStackAnnouncement } from "@/hooks/useBetterStackAnnouncement";

const App: React.FC = () => {
  useBetterStackAnnouncement();
  return (
    <React.StrictMode>
      <Suspense fallback="Loading...">
        <ThemeProvider theme={theme}>
          <ToastContextProvider>
            <GlobalStyle />
            <GlobalFontsNunito />
            <AppRerenderer>
              <UsersnapProvider>
                <Router>
                  <OnboardingProvider>
                    <SerialNumberProvider>
                      <TrackingContextProvider>
                        <Header />
                        <FaviconManager />
                        <Routes />
                      </TrackingContextProvider>
                    </SerialNumberProvider>
                  </OnboardingProvider>
                </Router>
              </UsersnapProvider>
            </AppRerenderer>
          </ToastContextProvider>
        </ThemeProvider>
      </Suspense>
    </React.StrictMode>
  );
};

export default App;
