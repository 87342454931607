/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, StyledText, Types, useToast } from "@emonvia/web-components";
import i18next from "i18next";
import React, { useState } from "react";
import DownArrow from "@/assets/images/downArrowWhite.svg";
import Selected from "@/assets/images/selected.svg";
import World from "@/assets/images/world.svg";
import { Language } from "@/utils/enums/Language";
import theme from "@/utils/theme/theme";
import { translateError } from "@/utils/translations/Translator";
import styled from "styled-components";
import { useOnboarding } from "@/hooks/useOnboarding";

interface IProps {
  longLanguageName?: boolean;
}

const Dropdown = styled.div`
  position: absolute;
  background: ${() => theme.colors.primaryWhite};
  width: 200px;
  right: 0;
  margin-top: 20px;
  border-radius: 10px;
  border: ${() => theme.borders.dropdown};
  z-index: ${() => theme.zIndex.dropdown};

  & .item {
    padding: 17px 20px;
    border-bottom: ${() => theme.borders.dropdownItem};
    line-height: normal;

    &:last-child {
      border-bottom: none;
    }
  }
`;

const languagesByShorthand: {
  [key in Language]: string;
} = {
  EN: "English",
  DE: "Deutsch",
  IT: "Italiano",
  FR: "Français",
  NL: "Nederlands"
} as const;

const allowedLanguages = [Language.DE, Language.EN, Language.NL, Language.FR, Language.IT];

const LanguageSwitch: React.FC<IProps> = ({ longLanguageName }) => {
  const { update } = useOnboarding();
  const [showDropdown, setShowDropdown] = useState(false);
  const { notifyError } = useToast();

  const toggleDropdown = () => setShowDropdown((prevShowDropdown) => !prevShowDropdown);

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const changeLanguage = async (language: Language) => {
    try {
      await i18next.changeLanguage(language.toLocaleLowerCase());
      await update({ language });
      closeDropdown();
    } catch (e: any) {
      notifyError(translateError(e));
    }
  };

  const currentLanguage = i18next.language && (i18next.language.toUpperCase() as Language);
  return (
    <Flex flexDirection="row" justifyContent="flex-end" flexWrap="nowrap">
      <div style={{ position: "relative", width: "auto" }}>
        <Flex
          alignItems="center"
          flexDirection="row"
          style={{ cursor: "pointer" }}
          onClick={toggleDropdown}
        >
          <img id="world-icon" src={World} width={20} height={20} />
          <StyledText
            text={longLanguageName ? languagesByShorthand[currentLanguage] : currentLanguage}
            type={Types.ns_16_sb}
            color={theme.colors.primaryWhite}
            margin={[0, 10]}
            style={{ borderBottom: theme.borders.yellowUnderline, cursor: "pointer" }}
          />
          <img src={DownArrow} width={20} height={20} />
        </Flex>
        {showDropdown && (
          <Dropdown>
            {allowedLanguages.map((language: Language, i) => (
              <Flex
                key={i}
                classNames={["item"]}
                onClick={() => changeLanguage(language)}
                justifyContent="space-between"
                style={{
                  cursor: "pointer"
                }}
              >
                <StyledText
                  text={languagesByShorthand[language]}
                  type={currentLanguage === language ? Types.ns_16_sb : Types.ns_16_l}
                  color={theme.colors.blue900}
                  style={{
                    cursor: "pointer"
                  }}
                />
                {currentLanguage === language && (
                  <img id="selected-icon" src={Selected} width={18} height={22} />
                )}
              </Flex>
            ))}
          </Dropdown>
        )}
      </div>
    </Flex>
  );
};

export default LanguageSwitch;
