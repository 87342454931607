import { useTranslation } from "@/utils/translations/Translator";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import supportImage from "@/assets/images/illustration-support.svg";
import InfoFrame from "@/components/InfoFrame";
import ErrorFrame from "@/components/ErrorFrame";
import { Trans } from "react-i18next";
import { StyledBlueLinkForTrans } from "@/components/TrackedComponents/reevComponents";
import allRoutes, { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { CONTACT_URL_BY_LANGUAGE, CPO_ONBOARDING_ID } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";
import Storage from "@/utils/Storage";
import { useNavigate } from "react-router-dom";

const ConnectivityUnsuccessful = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const nextStepHandler = () => {
    if (currentPath === allRoutes.CONNECTORS_TROUBLESHOOT_FAILED) {
      Storage.remove(CPO_ONBOARDING_ID);
      navigate(allRoutes.ROOT);
    } else goToNextStep();
  };

  return (
    <Flex flexDirection="column" justifyContent="center" gap={32}>
      <Flex justifyContent="center">
        <img width={80} height={82} src={supportImage} />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <ErrorFrame
          description={<StyledText text={t("PROBLEM_WAS'NT_RESOLVED")} type={Types.ns_16_sb} />}
        />
        <InfoFrame
          textType={Types.ns_16_m}
          description={
            <Trans
              i18nKey={
                currentPath === allRoutes.CONNECTIVITY_TROUBLESHOOT_UNSUCCESSFUL
                  ? "YOU_MAY_CLOSE_THE_WINDOW"
                  : "CONTACT_OUT_SUPPORT_TEAM"
              }
              components={[
                <StyledBlueLinkForTrans
                  key="CONTACT"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(CONTACT_URL_BY_LANGUAGE[getCurrentLanguage()])}
                  target="_blank"
                />
              ]}
            />
          }
        />
      </Flex>
      <Button label={t("RESTART")} useNunitoFont width="100%" onClick={nextStepHandler} />
    </Flex>
  );
};

export default ConnectivityUnsuccessful;
