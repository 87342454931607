import styled from "styled-components";
import { StyledText } from "@emonvia/web-components";

export const StyledLinkButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 0;
  background: none;
  cursor: pointer;
  :hover {
    filter: ${(props) => props.theme.states.hover.filter};
  }
  :active {
    filter: ${(props) => props.theme.states.active.filter};
  }
  :focus {
    outline: none;
  }
`;

export const StyledCopyText = styled(StyledText)`
  text-decoration: underline;
`;
