import theme from "@/utils/theme/theme";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import InfoFrame from "@/components/InfoFrame";
import { useTranslation } from "@/utils/translations/Translator";
import { Button, StyledBlueLinkForTrans } from "@/components/TrackedComponents/reevComponents";
import { Trans } from "react-i18next";
import { ONBOARDING_REEV_CONNECT } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";
import { useOnboarding } from "@/hooks/useOnboarding";
import EvseIdOrderedFrame from "@steps/ConnectorEvseID/components/EvseIdOrderedFrame";
import { StyledList } from "@/components/UserConsentModal/styles";
import QRCode from "@/assets/images/qr_block.svg";
import UpdateConnector from "@steps/ConnectorEvseID/components/UpdateConnector";
import { arraySorter } from "@/utils/helpers";
import { useEffect, useState } from "react";
import { IOnboardingConnector } from "@/api/ApiTypes";
import { goToNextStep, NEXT_STEP_TO_ONBOARDING_STEP, Path } from "@/routing/AllRoutes";

const ConnectorEvseID = () => {
  const { t } = useTranslation();
  const { data, update, isLoading } = useOnboarding();
  const [connectors, setConnectors] = useState<IOnboardingConnector[]>([]);
  const [validationStates, setValidationStates] = useState<boolean[]>([]);

  useEffect(() => {
    if (data?.chargePoint?.connectors) {
      setConnectors(data.chargePoint.connectors);

      if (!validationStates.length) {
        setValidationStates(new Array(data.chargePoint.connectors.length).fill(true));
      }
    }
  }, [data]);

  const handleEvseIdChange = (index: number, newEvseId: string, isValid: boolean) => {
    setConnectors((prevConnectors) => {
      const updatedConnectors = [...prevConnectors];
      updatedConnectors[index] = { ...updatedConnectors[index], evseId: newEvseId };
      return updatedConnectors;
    });
    setValidationStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = isValid;
      return updatedStates;
    });
  };

  const sortedConnectors = arraySorter(connectors, "connectorIdentifier") || [];

  const hasErrors = validationStates.some((isValid) => !isValid);

  const updateEvseIdInfo = async () => {
    const currentPath = location.pathname;
    const updatedConnectors = connectors.map((connector) => ({
      ...connector,
      evseId: connector.evseId.trim() === "" ? null : connector.evseId
    }));

    await update({
      chargePoint: { ...data.chargePoint, connectors: updatedConnectors },
      state: NEXT_STEP_TO_ONBOARDING_STEP[currentPath as Path]
    });
    await goToNextStep();
  };

  const goToNext = async () => {
    await updateEvseIdInfo();
  };

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <Flex
        padding={[20]}
        style={{
          backgroundColor: theme.colors.frameBodyBackground,
          borderRadius: "10px"
        }}
        flexDirection="column"
      >
        <Flex flexDirection="column" flex={1} gap={8}>
          <EvseIdOrderedFrame />
          {sortedConnectors.map((connector, index) => (
            <UpdateConnector
              key={connector.connectorIdentifier}
              connector={connector}
              connectors={connectors}
              onEvseIdChange={handleEvseIdChange}
              index={index}
            />
          ))}
        </Flex>
      </Flex>
      <InfoFrame
        description={
          <StyledText
            text={
              <StyledList>
                <li>
                  <Trans
                    i18nKey="THE_STICKERS_ARE_ENCLOSED_WITH_PRODUCT_MATERIALS"
                    components={[
                      <StyledBlueLinkForTrans
                        key={"THE_STICKERS_ARE_ENCLOSED_WITH_PRODUCT_MATERIALS" + "link"}
                        style={{ cursor: "pointer" }}
                        onClick={() => window.open(ONBOARDING_REEV_CONNECT[getCurrentLanguage()])}
                        target="_blank"
                      />
                    ]}
                  />
                </li>
                <li>
                  <StyledText
                    text={t("YOU_CAN_FIND_THE_EVSEID_DIRECTLY")}
                    style={{ margin: "24px 0 16px" }}
                    type={Types.ns_16_m}
                  />
                </li>
              </StyledList>
            }
            type={Types.ns_16_m}
          />
        }
        image={QRCode}
      />
      <Button
        label={t("CONFIRM")}
        useNunitoFont
        width="100%"
        margin={[32, 0, 0, 0]}
        onClick={goToNext}
        disabled={hasErrors || isLoading}
      />
    </Flex>
  );
};

export default ConnectorEvseID;
