import styled from "styled-components";
import { IStep } from "./types";
import { Icon } from "@emonvia/web-components";

export const StyledStep = styled.div<IStep>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid
    ${(props) =>
      props.filled || props.current ? props.theme.colors.main : props.theme.colors.tertiary};
  background: ${(props) => props.filled && props.theme.colors.main};
  position: relative;
  flex-shrink: 0;
`;

export const StyledCircle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.main};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledLineBefore = styled.div<IStep>`
  height: 2px;
  background: ${(props) =>
    props.filled || props.current ? props.theme.colors.main : props.theme.colors.tertiary};
  flex-grow: 1;
  width: 100%;
`;
