import React from "react";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import { AllRoutes, goToNextStep } from "@/routing/AllRoutes";
import { useTranslation } from "@/utils/translations/Translator";
import DataTextFrame from "@/components/DataTextFrame";
import SuccessFrame from "@/components/SuccessFrame";
import InfoFrame from "@/components/InfoFrame";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "@/hooks/useOnboarding";
import ConnectorIcon from "@/assets/images/connector.svg";
import { arraySorter } from "@/utils/helpers";
import { IOnboardingConnector } from "@/api/ApiTypes";

const ConnectorsAvailable: React.FC = () => {
  const { t } = useTranslation();
  const { data: onboardingData } = useOnboarding();
  const navigate = useNavigate();

  const connectors = onboardingData?.chargePoint?.connectors || [];

  const data = arraySorter(connectors, "connectorIdentifier").map(
    (connector: IOnboardingConnector) => {
      return {
        label: `${t("CONNECTOR")} ${connector.connectorIdentifier}`,
        value: connector.connectorIdentifier ? t("AVAILABLE") : t("NOT_AVAILABLE"),
        icon: ConnectorIcon
      };
    }
  );

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <SuccessFrame
        description={<StyledText type={Types.ns_16_sb} text={t("ALL_OK_CONNECTORS_HERE")} />}
      />
      <DataTextFrame header={t("RECEIVED_CONNECTORS_DETAILS")} data={data} />
      <InfoFrame textType={Types.ns_16_m} description={t("IS_UPDATED_LIST_COMPLETED")} />

      <Flex margin={[32, 0, 0, 0]} gap={8} flexDirection="column" alignItems="center">
        <Button
          label={t("YES_ALL_LISTED")}
          useNunitoFont
          width="100%"
          onClick={() => navigate(AllRoutes.CONNECTORS_STATUS)}
        />
        <Button
          label={t("NOT_ALL_LISTED")}
          useNunitoFont
          look="secondary"
          onClick={goToNextStep}
          width="100%"
        />
      </Flex>
    </Flex>
  );
};

export default ConnectorsAvailable;
