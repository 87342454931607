import React from "react";
import { Flex } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import DataTextFrame from "@/components/DataTextFrame";
import { IOnboardingConnector } from "@/api/ApiTypes";
import { useOnboarding } from "@/hooks/useOnboarding";
import ConnectorIcon from "@/assets/images/connector.svg";
import { arraySorter } from "@/utils/helpers";

const ConnectorsSection: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useOnboarding();

  const connectors = data?.chargePoint?.connectors || [];

  const connectorsData = arraySorter(connectors, "connectorIdentifier")?.map(
    (connector: IOnboardingConnector) => {
      return {
        label: `${t("EVSE_ID")} ${t("CONNECTOR")} ${connector?.connectorIdentifier}`,
        value: connector?.evseId || "-",
        icon: ConnectorIcon
      };
    }
  );

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <DataTextFrame data={connectorsData} />
    </Flex>
  );
};

export default ConnectorsSection;
