import { Flex, StyledText, Types } from "@emonvia/web-components";
import React, { useEffect, useRef } from "react";
import ValidatedTextInput from "@/components/ValidatedTextInput";
import theme from "@/utils/theme/theme";
import { useTranslation } from "@/utils/translations/Translator";
import { useSerialNumber } from "@/hooks/useSerialNumber";
import { ESerialNumberErrorStatus } from "@/context/SerialNumberContext";
import AllRoutes, { goToNextStep } from "@/routing/AllRoutes";
import { useOnboarding } from "@/hooks/useOnboarding";
import { AnimatedStyledText } from "@steps/ConnectivityCheck/styles";

export interface ChargePointInputProps {
  onErrorStatus?: (status?: ESerialNumberErrorStatus) => void;
}

const ChargePointInput: React.FC<ChargePointInputProps> = ({ onErrorStatus }) => {
  const { t } = useTranslation();
  const { update } = useOnboarding();
  const {
    serialNumber,
    onSerialNumberChange,
    serialNumberValid,
    serialNumberInvalid,
    serialNumberErrorMessage,
    errorStatus,
    chargePointData,
    isLoading
  } = useSerialNumber();

  const timerIdRef = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    onErrorStatus(errorStatus);
  }, [errorStatus]);

  useEffect(() => {
    if (timerIdRef.current || !serialNumberValid || isLoading) return;

    timerIdRef.current = setTimeout(() => {
      if (serialNumber) {
        onSerialNumberChange("");
        goToNextStep();
      }
    }, 3000);

    update({ chargePoint: chargePointData, state: AllRoutes.CONNECTIVITY_STATUS });

    return () => clearTimeout(timerIdRef.current);
  }, [chargePointData, serialNumberValid, isLoading]);

  return (
    <Flex
      margin={[0, 0, 4]}
      gap={10}
      alignItems="flex-start"
      style={{
        backgroundColor: theme.colors.frameBodyBackground,
        boxShadow: "none",
        borderRadius: "10px"
      }}
      padding={[20]}
    >
      <Flex flexDirection="column" flex={1} gap={12}>
        <StyledText
          text={t("ENTER_CHARGE_POINT_SERIAL_NUMBER_TO_CHECK_CONNECTION")}
          type={Types.ns_16_sb}
          color={theme.colors.grey750}
        />
        <ValidatedTextInput
          data-testid="ChargePointInput_SerialNumberInput"
          title={
            <Flex margin={[0, 0, 12]} gap={10}>
              {t("CHARGE_POINT_SERIAL_NUMBER")}
            </Flex>
          }
          placeholder={t("ENTER_CHARGE_POINT_SERIAL_NUMBER")}
          value={serialNumber}
          onChange={onSerialNumberChange}
          valid={serialNumberValid}
          error={serialNumberInvalid}
          errorMessage={serialNumberErrorMessage && t(serialNumberErrorMessage)}
          successMessage={
            serialNumberValid && (
              <AnimatedStyledText text={t("CHARGE_POINT_ONLINE")} type={Types.ns_16_m} />
            )
          }
          validators={["removeSpaces", { key: "limit", options: { limit: 25 } }]}
        />
      </Flex>
    </Flex>
  );
};

export default ChargePointInput;
