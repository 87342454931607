import React, { PropsWithChildren, useState } from "react";
import { Flex, Icon, IconTypes, StyledText, Types } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";
import { AccordionArrow, AccordionContainer, AccordionContent } from "./styles";

interface AccordionProps {
  title: string;
}

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleAccordion = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <AccordionContainer>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding={[20]}
        style={{ cursor: "pointer", backgroundColor: theme.colors.frameBodyBackground }}
        height={"22px"}
        onClick={toggleAccordion}
      >
        <StyledText type={Types.ns_16_sb} text={title} />
        <AccordionArrow isOpen={isOpen}>
          <Icon size={20} type={IconTypes.angleDown} />
        </AccordionArrow>
      </Flex>
      {isOpen && <AccordionContent isOpen={isOpen}>{children}</AccordionContent>}
    </AccordionContainer>
  );
};

export default Accordion;
