import React from "react";
import { Flex } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import DataTextFrame from "@/components/DataTextFrame";
import { getHeaderTextByKey } from "@/utils/helpers";
import { IGetChargePointResponse } from "@/api/ApiTypes";
import {
  connectivityIconWithKey,
  connectivityKeyMappings
} from "@steps/ConnectivityStatus/ConnectivityStatus";
import { useOnboarding } from "@/hooks/useOnboarding";

type ConnectivityIconKeyType = typeof connectivityIconWithKey;

const ChargePointSection: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useOnboarding();

  const mapDataToChargePointData = (data: IGetChargePointResponse) => {
    return {
      vendor: data?.vendor || "-",
      model: data?.model || "-",
      chargePointSerialNumber: data?.chargePointSerialNumber || "-",
      chargeBoxSerialNumber: data?.chargeBoxSerialNumber || "-",
      firmwareVersion: data?.firmwareVersion || "-",
      connectionType: data?.connectionType || "-"
    };
  };

  const languageKeys: Array<keyof ConnectivityIconKeyType> = Object.keys(
    connectivityKeyMappings
  ) as Array<keyof ConnectivityIconKeyType>;

  const mappedData = mapDataToChargePointData(data?.chargePoint);

  const cpData = languageKeys.map((key) => {
    const { key: label } = getHeaderTextByKey(key, connectivityKeyMappings);
    return {
      label: t(label),
      value: mappedData[key],
      icon: connectivityIconWithKey[key]
    };
  });

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <DataTextFrame data={cpData} />
    </Flex>
  );
};

export default ChargePointSection;
