import styled from "styled-components";
import { withTracking } from "@/context/TrackingContext";
import { translateToEnglish } from "@/utils/translations/Translator";

const StyledBlueLinkForTrans = styled.a`
  color: ${(props) => props.theme.colors.blue500} !important;
  text-decoration: underline !important;
  ::after {
    display: none;
  }
`;

const TrackedLink = withTracking(StyledBlueLinkForTrans, {
  actionAccessor: "onClick",
  generateMessage: (props) =>
    `Clicked link with text "${translateToEnglish(String(props.children))}"`
});

export default TrackedLink;
