import { FC } from "react";
import { Flex } from "@emonvia/web-components";
import Step from "./components/Step/Step";

export interface IProgressBar {
  steps: number;
  activeStep: number;
}

const ProgressBar: FC<IProgressBar> = ({ steps, activeStep }) => {
  return (
    <Flex width="100%" flexWrap="nowrap" justifyContent="center" alignItems="center">
      {[...Array(steps).keys()].map((step) => (
        <Step
          key={step}
          filled={step < activeStep}
          current={step === activeStep}
          lineBefore={step !== 0}
        />
      ))}
    </Flex>
  );
};

export default ProgressBar;
