import Storage from "@/utils/Storage";
import { ONBOARDING_START_TIME } from "@/utils/constants";
import { PostHogEvent } from "@/utils/interface/PostHogEvent";
import { PathValues } from "@/routing/AllRoutes";
import {
  DefaultOnboardingStepNameByRoute,
  getOnboardingStepFromCurrentRoute
} from "@/context/TrackingContext";

const nextButtonClick: PostHogEvent = {
  eventName: "Next button clicked"
};

const backButtonClick = "Clicked button with icon “back arrow”";

const languageSelector = "Clicked dropdown with language selector";

const timeOnPage = (startTime: number, locationPathName: PathValues): PostHogEvent => {
  const endTime = Date.now();
  return {
    eventName: "Time on page",
    properties: {
      Component: DefaultOnboardingStepNameByRoute[locationPathName],
      timeSpent: endTime - startTime
    }
  };
};

const onboardingTime = (): PostHogEvent => {
  const endTime = Date.now();
  const startTime = parseInt(Storage.get(ONBOARDING_START_TIME));

  return {
    eventName: "Time to complete onboarding",
    properties: {
      timeSpent: endTime - startTime,
      Component: getOnboardingStepFromCurrentRoute()
    }
  };
};

const emailEvent = (emailType: string): PostHogEvent => ({
  eventName: `Clicked button with text ${emailType}`,
  properties: {
    Component: getOnboardingStepFromCurrentRoute()
  }
});

const PostHogEvents = {
  nextButtonClick,
  backButtonClick,
  onboardingTime,
  timeOnPage,
  emailEvent,
  languageSelector
};

export default PostHogEvents;
