import React from "react";
import { isNotEmpty, RenderIf, StyledText, Types, useToast } from "@emonvia/web-components";
import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import theme from "@/utils/theme/theme";
import { StyledScannerModal, ScannerWrapper } from "@/components/QRCodeScanner/styles";
import { isEvseId } from "@/utils/helpers";

interface IProps {
  onChange: (code: string) => void;
  title?: string;
  showModal?: boolean;
  setShowModal?: (val: boolean) => void;
  setErrorMessage?: (val: TransKey | "") => void;
}

const QrCodeScanner: React.FC<IProps> = ({
  title,
  showModal,
  onChange,
  setShowModal,
  setErrorMessage
}) => {
  const { t } = useTranslation();
  const { notifyError } = useToast();

  const handleScan = (result: IDetectedBarcode[]) => {
    try {
      const code = result[0]?.rawValue;
      const params = new URL(code)?.searchParams;

      const evseid = params.get("evseid");

      if (isNotEmpty(evseid)) {
        const evseidParts = evseid.split("*");
        const validEvseid = evseidParts.find((part) => isEvseId(part));

        if (validEvseid) {
          onChange(validEvseid);
          setShowModal(false);
        } else {
          setErrorMessage("INVALID_EVSEID");
        }
      } else {
        setErrorMessage("NO_EVSEID_DETECTED");
        setShowModal(false);
      }
    } catch (error) {
      setErrorMessage(error?.message || "UNKNOWN_ERROR");
      setShowModal(false);
    }
  };

  const handleError = (error: Error) => {
    setShowModal(false);
    notifyError(`${error.name}: ${error.message}`);
  };

  return (
    <>
      <RenderIf renderContent={showModal}>
        <StyledScannerModal
          header={
            <StyledText
              text={title}
              type={Types.ns_18_sb}
              color={theme.colors.primaryWhite}
              onClick={() => setShowModal(false)}
            />
          }
          size={"small"}
          subhead={
            <StyledText
              text={t("SCAN_EVSEID_WITH_YOUR_CAMERA")}
              type={Types.ns_16_sb}
              textAlign="center"
              color={theme.colors.primaryWhite}
              onClick={() => setShowModal(false)}
              margin={[22, 0, 18]}
            />
          }
          subheadPositionBellow
          onClose={() => setShowModal(false)}
          actions={
            <>
              <StyledText
                text={t("ENTER_CODE_MANUALLY")}
                type={Types.ns_16_sb}
                color={theme.colors.primaryWhite}
                onClick={() => setShowModal(false)}
                style={{ borderBottom: theme.borders.yellowUnderline, cursor: "pointer" }}
              />
            </>
          }
          look={"primary"}
        >
          <ScannerWrapper>
            <Scanner
              scanDelay={300}
              onScan={handleScan}
              onError={handleError}
              constraints={{ facingMode: "environment" }}
              styles={{
                container: { overflow: "hidden" },
                finderBorder: 72
              }}
              components={{ torch: true, zoom: true }}
            />
          </ScannerWrapper>
        </StyledScannerModal>
      </RenderIf>
    </>
  );
};

export default QrCodeScanner;
