import { Flex, StyledText, Types } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import InfoFrame from "@/components/InfoFrame";
import updateImage from "@/assets/images/illustration-update.svg";
import GuideTextFrame from "@/components/GuideTextFrame";
import infoQuestionIcon from "@/assets/images/infoQuestionIcon.svg";
import { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";

const ConnectivityFwRetry = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={32}>
      <Flex justifyContent="center">
        <img width={116} height={82} src={updateImage} />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <GuideTextFrame
          description={
            <StyledText text={t("THANKS_FOR_UPDATING_FIRMWARE")} type={Types.ns_16_sb} />
          }
        />
        <InfoFrame
          icon={infoQuestionIcon}
          description={
            <StyledText text={t("IF_PROBLEM_PERSIST")} type={Types.ns_16_m} margin={[7, 0, 0]} />
          }
        />
      </Flex>
      <Button label={t("RETRY")} useNunitoFont width="100%" onClick={goToNextStep} />
    </Flex>
  );
};

export default ConnectivityFwRetry;
