import React, { PropsWithChildren, useState } from "react";
import { StyledText, Types } from "@emonvia/web-components";
import { TooltipArrow, TooltipContainer } from "@/components/Tooltip/styles";

const TooltipWrapper: React.FC<PropsWithChildren<{ content: string | React.ReactElement }>> = ({
  content,
  children
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ display: "inline-block", position: "relative" }}
    >
      {children}
      {isHovered && (
        <>
          <TooltipContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <StyledText text={content} type={Types.ns_14_l} />
          </TooltipContainer>
          <TooltipArrow />
        </>
      )}
    </div>
  );
};

export default TooltipWrapper;
