import React, { ReactNode } from "react";
import { Flex, Frame, StyledText, Types } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";

type IProps = {
  header?: string;
  description: ReactNode;
  icon?: string;
  sectionNumber?: number;
  margin?: number[];
};

const GuideTextFrame: React.FC<IProps> = ({ header, description, sectionNumber, icon, margin }) => {
  return (
    <Frame
      data-testid={sectionNumber + "Text_Frame"}
      border={{ borderRadius: "10px" }}
      margin={margin}
      style={{
        backgroundColor: theme.colors.frameBodyBackground,
        boxShadow: "none"
      }}
    >
      <Flex flexDirection="column" gap={12}>
        {(!!sectionNumber || !!icon) && (
          <Flex flexDirection="row" gap={12} alignItems="center">
            {!!icon && <img src={icon} alt={`${icon} + img`} />}
            {!!sectionNumber && (
              <StyledText text={`${sectionNumber}. ${header}`} type={Types.ns_20_b} />
            )}
          </Flex>
        )}
        <Flex flexDirection="row">
          <StyledText text={description} type={Types.ns_16_l} />
        </Flex>
      </Flex>
    </Frame>
  );
};

export default GuideTextFrame;
