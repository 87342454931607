import { FC } from "react";
import { StyledCircle, StyledIcon, StyledLineBefore, StyledStep } from "./styles";
import { IconTypes } from "@emonvia/web-components";
import { IStep } from "@/components/ProgressBar/components/Step/types";

const Step: FC<IStep> = (props) => {
  const { current, filled, lineBefore } = props;

  return (
    <>
      {lineBefore && <StyledLineBefore {...props} />}
      <StyledStep {...props}>
        {current && <StyledCircle />}
        {filled && <StyledIcon size={14} color="primaryWhite" type={IconTypes.check} />}
      </StyledStep>
    </>
  );
};

export default Step;
