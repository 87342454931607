import React from "react";

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class DefaultErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error("ERROR", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <h1 data-testid="DefaultErrorBoundary_Fallback" style={{ textAlign: "center" }}>
          Something went wrong. please <a href="/start">start</a> again.
        </h1>
      );
    }
    return this.props.children;
  }
}
