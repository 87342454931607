import React, { ReactNode } from "react";
import { Flex, Frame, isNotEmpty, StyledText, Types } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";
import InfoIcon from "@/assets/images/infoIcon.svg";

type IProps = {
  description: ReactNode;
  icon?: string;
  textType?: Types;
  linkText?: ReactNode;
  image?: string;
};

const InfoFrame: React.FC<IProps> = ({
  description,
  icon = InfoIcon,
  linkText = "",
  image,
  textType = Types.ns_16_sb
}) => {
  return (
    <Frame
      data-testid={"Info_Frame"}
      border={{ borderRadius: "10px" }}
      style={{ backgroundColor: theme.colors.infoBackground, boxShadow: "none" }}
    >
      <Flex flexDirection="column">
        <Flex flexDirection="row" gap={12}>
          <Flex flexDirection="column" gap={12} alignItems="flex-start" margin={[3, 0, 0, 0]}>
            <img src={icon} alt={`${icon} + img`} />
          </Flex>
          <Flex flexDirection="column" flex={1}>
            <StyledText text={description} type={textType} color={theme.colors.grey750} />
            {linkText}
          </Flex>
        </Flex>
        {isNotEmpty(image) && (
          <Flex flexDirection="row" justifyContent="center">
            <img src={image} alt={image + "-image"} />
          </Flex>
        )}
      </Flex>
    </Frame>
  );
};

export default InfoFrame;
