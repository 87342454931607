import styled from "styled-components";
import { Modal } from "@emonvia/web-components";

export const StyledScannerModal = styled(Modal)`
  & > div {
    padding: 0;
    background: ${({ theme }) => theme.colors.modalBackground};
    width: 512px;
    & > i {
      color: ${({ theme }) => theme.colors.primaryWhite} !important;
    }
  }
  .footer {
    width: unset;
    padding: 32px 16px;
  }
  .modal-actions {
    max-width: unset;
    margin: 0;
    flex-direction: column;
    button {
      width: 100%;
      max-width: unset;
    }
  }
`;

export const ScannerWrapper = styled.div`
  z-index: 9999;
  svg rect,
  svg path {
    stroke: ${(props) => props.theme.colors.main500} !important;
  }
`;
