import styled from "styled-components";

export const AccordionContainer = styled.div`
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 10px;
`;

export const AccordionArrow = styled.div<{ isOpen: boolean }>`
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
  transition: transform 0.3s ease-in-out;
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  max-height: ${(props) => (props.isOpen ? "auto" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;
