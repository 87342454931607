export const AllRoutes = {
  ROOT: "/",
  CONFIG_WIZARD_ROOT: "/start",
  CONNECTIVITY_CHECK: "/connectivity-check",
  CONNECTIVITY_STATUS: "/connectivity-status",
  CONNECTIVITY_NO_FOUND: "/connectivity-not-found",
  CONNECTIVITY_TROUBLESHOOT_GRID: "/connectivity-trbl-grid",
  CONNECTIVITY_TROUBLESHOOT_BE: "/connectivity-trbl-be",
  CONNECTIVITY_TROUBLESHOOT_COMM: "/connectivity-trbl-comm",
  CONNECTIVITY_TROUBLESHOOT_FW: "/connectivity-trbl-fw",
  CONNECTIVITY_TROUBLESHOOT_REBOOT: "/connectivity-trbl-reboot",
  CONNECTIVITY_TROUBLESHOOT_DONE: "/connectivity-trbl-retry",
  CONNECTIVITY_TROUBLESHOOT_UNSUCCESSFUL: "/connectivity-no",
  CONNECTIVITY_PRE_CONFIG_OFFLINE: "/connectivity-preconfig-offline",
  CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_FW_RETRY: "/connectivity-preconfig-trbl-fw",
  CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_GRID: "/connectivity-preconfig-trbl-grid",
  CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_BE: "/connectivity-preconfig-trbl-be",
  CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_COMM: "/connectivity-preconfig-trbl-comm",
  CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_REBOOT: "/connectivity-preconfig-trbl-reboot",
  CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_DONE: "/connectivity-preconfig-trbl-retry",
  CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_UNSUCCESSFUL: "/connectivity-preconfig-no",
  CONNECTORS_STATUS: "/connectors-status",
  CONNECTOR_EVSEID: "/evse-id",
  ADD_INFO: "/add-info",
  CONNECTORS_TROUBLESHOOT_GROUPING: "/connectors-trbl-grouping",
  CONNECTORS_TROUBLESHOOT_AVAILABLE: "/connectors-trbl-available",
  CONNECTORS_TROUBLESHOOT_FAILED: "/connectors-trbl-failed",
  INSTALLATION_REPORT: "/installation-report",
  EMAIL_NEWSLETTER: "/email-newsletter"
} as const;

export type PathValues = typeof AllRoutes[keyof typeof AllRoutes];

export type RouteKey = keyof typeof AllRoutes;
export type Path = typeof AllRoutes[RouteKey];

export const mainRoutes = [
  // TROUBLESHOOTING
  AllRoutes.CONNECTIVITY_NO_FOUND,
  AllRoutes.CONNECTIVITY_TROUBLESHOOT_GRID,
  AllRoutes.CONNECTIVITY_TROUBLESHOOT_BE,
  AllRoutes.CONNECTIVITY_TROUBLESHOOT_COMM,
  AllRoutes.CONNECTIVITY_TROUBLESHOOT_FW,
  AllRoutes.CONNECTIVITY_TROUBLESHOOT_REBOOT,
  AllRoutes.CONNECTIVITY_TROUBLESHOOT_DONE,
  AllRoutes.CONNECTIVITY_TROUBLESHOOT_UNSUCCESSFUL,

  AllRoutes.CONNECTIVITY_PRE_CONFIG_OFFLINE,
  AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_FW_RETRY,
  AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_GRID,
  AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_BE,
  AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_COMM,
  AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_REBOOT,
  AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_DONE,
  AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_UNSUCCESSFUL,

  AllRoutes.CONNECTORS_TROUBLESHOOT_GROUPING,
  AllRoutes.CONNECTORS_TROUBLESHOOT_AVAILABLE,
  AllRoutes.CONNECTORS_TROUBLESHOOT_FAILED,

  // MAIN FLOW
  AllRoutes.CONFIG_WIZARD_ROOT,
  AllRoutes.CONNECTIVITY_CHECK,
  AllRoutes.CONNECTIVITY_STATUS,
  AllRoutes.CONNECTORS_STATUS,
  AllRoutes.CONNECTOR_EVSEID,
  AllRoutes.ADD_INFO,
  AllRoutes.INSTALLATION_REPORT
  // AllRoutes.EMAIL_NEWSLETTER
];

type TROUTE_TO_ROUTE = {
  [key in Path]?: Path;
};

const NEXT_STEP_TO_ROUTE: TROUTE_TO_ROUTE = {
  [AllRoutes.CONNECTIVITY_TROUBLESHOOT_UNSUCCESSFUL]: AllRoutes.CONNECTIVITY_CHECK,
  [AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_UNSUCCESSFUL]: AllRoutes.CONNECTIVITY_CHECK,
  [AllRoutes.CONNECTORS_TROUBLESHOOT_FAILED]: AllRoutes.CONNECTORS_STATUS
};
const BACK_STEP_TO_ROUTE: TROUTE_TO_ROUTE = {
  [AllRoutes.CONNECTIVITY_NO_FOUND]: AllRoutes.CONNECTIVITY_CHECK,
  [AllRoutes.CONNECTIVITY_PRE_CONFIG_OFFLINE]: AllRoutes.CONNECTIVITY_CHECK,
  [AllRoutes.CONNECTORS_TROUBLESHOOT_GROUPING]: AllRoutes.CONNECTORS_STATUS
};

export const NEXT_STEP_TO_ONBOARDING_STEP: TROUTE_TO_ROUTE = {
  [AllRoutes.CONFIG_WIZARD_ROOT]: AllRoutes.CONNECTIVITY_CHECK,
  [AllRoutes.CONNECTIVITY_STATUS]: AllRoutes.CONNECTORS_STATUS,
  [AllRoutes.CONNECTORS_STATUS]: AllRoutes.CONNECTOR_EVSEID,
  [AllRoutes.CONNECTOR_EVSEID]: AllRoutes.ADD_INFO,
  [AllRoutes.ADD_INFO]: AllRoutes.INSTALLATION_REPORT
  // [AllRoutes.INSTALLATION_REPORT]: AllRoutes.EMAIL_NEWSLETTER
};

export const BACK_STEP_TO_ONBOARDING_STEP: TROUTE_TO_ROUTE = {
  [AllRoutes.CONNECTIVITY_STATUS]: AllRoutes.CONNECTIVITY_CHECK,
  [AllRoutes.CONNECTORS_STATUS]: AllRoutes.CONNECTIVITY_STATUS,
  [AllRoutes.CONNECTOR_EVSEID]: AllRoutes.CONNECTORS_STATUS,
  [AllRoutes.ADD_INFO]: AllRoutes.CONNECTOR_EVSEID,
  [AllRoutes.INSTALLATION_REPORT]: AllRoutes.ADD_INFO
};

const getPreviousPath = async (currentPath: string) => {
  try {
    if (BACK_STEP_TO_ROUTE[currentPath as Path]) return BACK_STEP_TO_ROUTE[currentPath as Path];

    const index = mainRoutes.findIndex((stepPath) => stepPath === currentPath);
    const previousRouteExists = index !== -1 && index !== 0;
    if (previousRouteExists) {
      return mainRoutes[index - 1];
    } else {
      return AllRoutes.CONFIG_WIZARD_ROOT;
    }
  } catch (e) {
    globalNotifyError.current("UNKNOWN_ERROR");
  }
};

const getNextPath = async (currentPath: string) => {
  try {
    if (NEXT_STEP_TO_ROUTE[currentPath as Path]) return NEXT_STEP_TO_ROUTE[currentPath as Path];

    const index = mainRoutes.findIndex((stepPath) => stepPath === currentPath);
    const nextRouteExists = index !== -1;
    if (nextRouteExists) {
      return mainRoutes[index + 1];
    } else {
      return AllRoutes.CONFIG_WIZARD_ROOT;
    }
  } catch (e) {
    globalNotifyError.current("UNKNOWN_ERROR");
  }
};

export const globalNavigate = {
  // @typescript-eslint/no-unused-vars
  current: (_path: string): void | null => null
};

export const globalNotifyError = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  current: (_message: string): void | null => null
};

export const restartWizard = () => {
  const firstPath = AllRoutes.CONFIG_WIZARD_ROOT;
  globalNavigate.current(firstPath);
};

export const goToPreviousStep = async () => {
  const { pathname, search } = location;
  const nextPath = await getPreviousPath(pathname);
  if (nextPath) {
    globalNavigate.current(nextPath + search);
  }
};

export const goToNextStep = async () => {
  const { pathname, search } = location;
  const nextPath = await getNextPath(pathname);
  if (nextPath) {
    globalNavigate.current(nextPath + search);
  }
};

export const navigateWithQuery = async (path: string) => {
  const { search } = location;
  if (path) {
    globalNavigate.current(path + search);
  }
};

export default AllRoutes;
