import React, { useEffect, useRef, useState } from "react";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import theme from "@/utils/theme/theme";
import GuideFrame from "@/containers/InitialScreen/components/GuideFrame";
import UserConsentModal from "@/components/UserConsentModal/UserConsentModal";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { useOnboarding } from "@/hooks/useOnboarding";
import { useQueryParams } from "@/hooks/useQueryParams";
import Storage from "@/utils/Storage";
import { ACCEPTED, AGREED_TO_ANALYTICS_CONSENT } from "@/utils/constants";
import { initializeTracking } from "@/hooks/usePostHog";

const InitialScreen: React.FC = () => {
  const { t } = useTranslation();
  const { goNextWithUpdate, start, isLoading } = useOnboarding();
  const { areQueryParamsPresent } = useQueryParams();
  const isOnboardingInProgress = useRef(false);
  const [posthogAccepted, setPosthogAccepted] = useState(false);

  useEffect(() => {
    if (areQueryParamsPresent) {
      startOnboarding();
      submitPreference(ACCEPTED);
    }
  }, [areQueryParamsPresent]);

  const startOnboarding = () => {
    if (isOnboardingInProgress.current) return;
    isOnboardingInProgress.current = true;

    start()
      .then(() => {
        void goNextWithUpdate();
      })
      .finally(() => {
        isOnboardingInProgress.current = false;
      });
  };

  const submitPreference = async (preference: string) => {
    try {
      setPosthogAccepted(true);
      Storage.set(AGREED_TO_ANALYTICS_CONSENT, preference);
      initializeTracking();
    } catch {
      setPosthogAccepted(false);
    }
  };

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <StyledText
        data-testid={"InitialScreen_FinishSetupText"}
        text={t("REEV_E_WIZARD")}
        type={Types.ns_24_sb}
        color={theme.colors.primaryBlack}
        textAlign="center"
        margin={[0, 0, 8, 0]}
      />
      <StyledText
        data-testid={"InitialScreen_ActivateYourAccountText"}
        text={t("FAST_SIMPLE_RELIABLE")}
        type={Types.ns_16_l}
        color={theme.colors.grey750}
        textAlign="center"
        margin={[0, 0, 18, 0]}
      />
      <GuideFrame />
      <Button
        data-testid={"InitialFrame_StartSetupButton"}
        label={t("START_CONFIGURATION")}
        useNunitoFont
        width="100%"
        margin={[34, 0, 0, 0]}
        disabled={isLoading || isOnboardingInProgress.current}
        onClick={startOnboarding}
      />
      <UserConsentModal posthogAccepted={posthogAccepted} submitPreference={submitPreference} />
    </Flex>
  );
};

export default InitialScreen;
