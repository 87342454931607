import styled from "styled-components";
import { Modal } from "@emonvia/web-components";

export const StyledUserConsentModal = styled(Modal)`
  & > div {
    padding: 0;
    background: ${(props) => props.theme.colors.primaryWhite};
    max-width: 560px;
  }
  .body {
    display: block;
    margin: 20px 8px 20px 16px;
  }
  .footer {
    width: unset;
    box-shadow: 0px -6px 12px 0px rgba(0, 0, 0, 0.08);
    padding: 24px 16px 16px;
  }
  .modal-actions {
    max-width: unset;
    margin: 0;
    gap: 12px;
    flex-direction: column;
    button {
      width: 100%;
      max-width: unset;
    }
  }
`;

export const StyledList = styled.ul`
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 22px;
  li {
    &::marker {
      color: ${(props) => props.theme.colors.grey750};
    }
  }
`;
