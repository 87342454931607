import React, { PropsWithChildren, useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";
import env from "@/env";

interface SentryProviderProps {
  dsn: string;
}

const SentryErrorProvider: React.FC<PropsWithChildren<SentryProviderProps>> = ({
  dsn,
  children
}) => {
  if (dsn) {
    Sentry.init({
      environment: env.stage || "dev",
      dsn,
      release: env.version,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        Sentry.browserTracingIntegration()
      ],
      tracesSampleRate: env.sentryTraceSampleRate | 1.0
    });

    return (
      <Sentry.ErrorBoundary
        fallback={(error) => (
          <div data-testid="qa-sentry-error-boundary--fallback">
            <h1>Something went wrong!</h1>
            <p>Message: {error.error.message}</p>
            <p>{JSON.stringify(error)}</p>
          </div>
        )}
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  } else {
    return null;
  }
};

export default SentryErrorProvider;
