import React, { useState } from "react";
import { Flex, IconTypes, StyledText, Types, useToast } from "@emonvia/web-components";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import Accordion from "@/components/Accordian/Accordian";
import ChargePointSection from "@steps/InstallationReport/components/ChargePointSection";
import SuccessFrame from "@/components/SuccessFrame";
import InfoFrame from "@/components/InfoFrame";
import { StyledBadge, StyledDivider, StyledIconButton, StyledOptionsContainer } from "./styles";
import { Button } from "@/components/TrackedComponents/reevComponents";
import ConnectorsSection from "@steps/InstallationReport/components/ConnectorsSection";
import { useEmailValidation } from "@/hooks/useEmailValidation";
import ReevApiCalls from "@/api/ReevApiCalls";
import Storage from "@/utils/Storage";
import {
  ABL_SERIAL_NUMBER,
  CPO_ONBOARDING_ID,
  GROUP_LIMIT,
  ONBOARDING_START_TIME
} from "@/utils/constants";
import { trackEvent, usePostHog } from "@/hooks/usePostHog";
import { restartWizard } from "@/routing/AllRoutes";
import { mediaDownload, TransKey } from "@/utils/helpers";
import { ISendEmailRequest } from "@/api/ApiTypes";
import SendEmailButton from "@/components/SendEmailButton/SendEmailButton";
import { useOnboarding } from "@/hooks/useOnboarding";
import PostHogEvents from "@/utils/postHogEvents";
import ConsentCheckboxes from "@steps/InstallationReport/components/ConsentCheckboxes";
import { useQueryParams } from "@/hooks/useQueryParams";

const InstallationReport: React.FC = () => {
  const { completeOnboarding } = useOnboarding();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useToast();
  const { reset } = usePostHog();
  const { removeQueryParam } = useQueryParams();

  const [isLoading, setIsLoading] = useState(false);
  const [wasReportDownloaded, setWasReportDownloaded] = useState(false);
  const [agreeToEmail, setAgreeToEmail] = useState(false);
  const [agreeToNewsletter, setAgreeToNewsletter] = useState(false);

  const {
    email: electricianEmail,
    isValid: isElectricianEmailValid,
    handleChange: handleElectricianChange,
    emailErrorMessage: electricianErrorMessage,
    validateEmail: validateElectricianEmail
  } = useEmailValidation();

  const {
    email: customerEmail,
    isValid: isCustomerEmailValid,
    handleChange: handleCustomerChange,
    emailErrorMessage: customerErrorMessage,
    validateEmail: validateCustomerEmail
  } = useEmailValidation();

  const resetPosthogAndClearCacheHandler = () => {
    reset();
    Storage.remove(CPO_ONBOARDING_ID);
    Storage.remove(ONBOARDING_START_TIME);
    removeQueryParam(ABL_SERIAL_NUMBER);
    removeQueryParam(GROUP_LIMIT);
  };

  const downloadReportHandler = async () => {
    try {
      const onboardingId = Storage.get(CPO_ONBOARDING_ID);
      const pdfContent = await ReevApiCalls.createPdf(
        { pathParams: { onboardingId } },
        { responseType: "blob" }
      );

      mediaDownload("OnboardingComplete.pdf", pdfContent, "application/pdf");
      setWasReportDownloaded(true);
      notifySuccess(t("DOWNLOAD_SUCCESSFUL"));
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  const sendEmailToHandler = async (payload: ISendEmailRequest, message: TransKey) => {
    try {
      const onboardingId = Storage.get(CPO_ONBOARDING_ID);
      await ReevApiCalls.sendEmail({ payload, pathParams: { onboardingId } });
      notifySuccess(t(message));
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  const sendEmailToElectrician = async () => {
    await sendEmailToHandler(
      { email: electricianEmail, recipientType: "ELECTRICIAN" },
      "ELECTRICIAN_EMAIL_SENT"
    );
  };

  const sendEmailToCustomer = async () => {
    await sendEmailToHandler(
      { email: customerEmail, recipientType: "CUSTOMER" },
      "CUSTOMERS_EMAIL_SENT"
    );
  };

  const FinishOnboarding = async () => {
    const newsletterEmail: string = agreeToNewsletter ? electricianEmail : null;
    try {
      setIsLoading(true);
      await completeOnboarding(newsletterEmail);
      resetPosthogAndClearCacheHandler();
      setTimeout(() => {
        restartWizard();
      }, 2000);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <Flex flexDirection="column" gap={16} justifyContent="center">
      <SuccessFrame description={t("CONFIGURATION_COMPLETED")} />
      <InfoFrame
        description={<StyledText type={Types.ns_16_l} text={t("HAND_OVER_USER_PACK")} />}
      />
      <Accordion title={t("CHARGE_POINT_DETAILS_FOR_RECORDS")}>
        <ChargePointSection />
      </Accordion>
      <Accordion title={t("CONNECTORS_DETAILS_FOR_RECORDS")}>
        <ConnectorsSection />
      </Accordion>
      <StyledOptionsContainer gap={12} flexDirection="column">
        <StyledText type={Types.ns_16_l} text={t("CHOOSE_REPORT_OPTIONS")} />
        <Flex flexDirection="column" gap={16}>
          <Flex gap={8} alignItems="center">
            <Flex flexDirection="column" style={{ maxWidth: "calc(100% - 52px)" }}>
              <StyledText type={Types.ns_16_b} text={t("DOWNLOAD_PDF")} margin={[0, 0, 8, 0]} />
              <StyledText
                type={Types.ns_14_m}
                text={t("WE_WILL_CREATE_PDF_REPORT")}
                margin={[0, 0, 16, 0]}
              />
            </Flex>
            <StyledIconButton
              data-testid="DownloadPdfCheckbox"
              look="secondary"
              icon={IconTypes.pdf}
              iconSize={30}
              shape="square"
              width="44px"
              clicked={wasReportDownloaded}
              onClick={downloadReportHandler}
            />
          </Flex>
          <StyledDivider />
          <Flex flexDirection="column">
            <StyledText type={Types.ns_16_b} text={t("EMAIL_TO_ME")} margin={[0, 0, 8, 0]} />
            <StyledText
              type={Types.ns_14_m}
              text={t("ENTER_EMAIL_REPORT")}
              margin={[0, 0, 16, 0]}
            />
            <SendEmailButton
              dataTestid="ENTER_YOUR_EMAIL"
              placeholder={t("ENTER_YOUR_EMAIL")}
              margin={[0, 0, 12, 0]}
              value={electricianEmail}
              valid={isElectricianEmailValid}
              error={!isElectricianEmailValid}
              errorMessage={electricianErrorMessage && t(electricianErrorMessage)}
              onChange={handleElectricianChange}
              disableButton={!agreeToEmail}
              validators={["removeSpaces", { key: "limit", options: { limit: 50 } }]}
              onButtonClick={(setIsSent, setIsDisabled) => {
                validateElectricianEmail({
                  onSuccess: async () => {
                    setIsDisabled(true);
                    await sendEmailToElectrician();
                    setIsDisabled(false);
                    setIsSent(true);
                  }
                });
                trackEvent(PostHogEvents.emailEvent(t("EMAIL_TO_ME")));
              }}
            />
            <ConsentCheckboxes
              agreeToEmail={agreeToEmail}
              agreeToNewsletter={agreeToNewsletter}
              setAgreeToEmail={setAgreeToEmail}
              setAgreeToNewsletter={setAgreeToNewsletter}
            />
          </Flex>
          <StyledDivider />
          <Flex flexDirection="column">
            <Flex gap={8} alignItems="center" margin={[0, 0, 8, 0]}>
              <StyledText type={Types.ns_16_b} text={t("EMAIL_TO_CUSTOMER")} />
              <StyledBadge size={12} type={Types.ns_14_m} text={t("OUT_SUGGESTION")} />
            </Flex>
            <StyledText
              type={Types.ns_14_m}
              text={t("ENTER_CUSTOMER_EMAIL_REPORT")}
              margin={[0, 0, 16, 0]}
            />
            <SendEmailButton
              dataTestid="ENTER_CUSTOMER_EMAIL"
              placeholder={t("ENTER_CUSTOMER_EMAIL")}
              value={customerEmail}
              valid={isCustomerEmailValid}
              error={!isCustomerEmailValid}
              errorMessage={customerErrorMessage && t(customerErrorMessage)}
              onChange={handleCustomerChange}
              validators={["removeSpaces", { key: "limit", options: { limit: 50 } }]}
              onButtonClick={(setIsSent, setIsDisabled) => {
                validateCustomerEmail({
                  onSuccess: async () => {
                    setIsDisabled(true);
                    await sendEmailToCustomer();
                    setIsDisabled(false);
                    setIsSent(true);
                  }
                });
                trackEvent(PostHogEvents.emailEvent(t("EMAIL_TO_CUSTOMER")));
              }}
            />
          </Flex>
        </Flex>
      </StyledOptionsContainer>
      <Button
        data-testid={"ReportButton"}
        label={t("FINISH")}
        useNunitoFont
        width="100%"
        margin={[16, 0, 0, 0]}
        disabled={isLoading}
        onClick={FinishOnboarding}
      />
    </Flex>
  );
};

export default InstallationReport;
