import ProgressBar, { IProgressBar } from "@/components/ProgressBar/ProgressBar";
import { Flex, List, StyledText, Types } from "@emonvia/web-components";
import connectionImage from "@/assets/images/illustration-connection.svg";
import GuideTextFrame from "@/components/GuideTextFrame";
import InfoFrame from "@/components/InfoFrame";
import infoQuestionIcon from "@/assets/images/infoQuestionIcon.svg";
import { useTranslation } from "@/utils/translations/Translator";
import { Trans } from "react-i18next";
import { FC } from "react";
import { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";

const ConnectivityComm: FC<IProgressBar> = (props) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={32}>
      <ProgressBar {...props} />
      <Flex justifyContent="center">
        <img width={108} height={82} src={connectionImage} />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <GuideTextFrame
          description={<StyledText text={t("CHECK_NETWORK_SETTINGS")} type={Types.ns_16_sb} />}
        />
        <InfoFrame
          icon={infoQuestionIcon}
          description={
            <Flex gap={16} flexDirection="column">
              <StyledText text={t("FOR_LTE_APN")} type={Types.ns_16_m} />
              <div>
                <StyledText text={t("APN_INFORMATION")} type={Types.ns_16_m} />
                <List>
                  <li style={{ margin: 0 }}>
                    <StyledText
                      text={
                        <Trans
                          i18nKey="APN"
                          components={{
                            u: <u />
                          }}
                        />
                      }
                      type={Types.ns_16_m}
                    />
                  </li>
                  <li style={{ margin: 0 }}>
                    <StyledText
                      text={
                        <Trans
                          i18nKey="APN_USERNAME"
                          components={{
                            u: <u />
                          }}
                        />
                      }
                      type={Types.ns_16_m}
                    />
                  </li>
                  <li style={{ margin: 0 }}>
                    <StyledText
                      text={
                        <Trans
                          i18nKey="APN_PASSWORD"
                          components={{
                            u: <u />
                          }}
                        />
                      }
                      type={Types.ns_16_m}
                    />
                  </li>
                </List>
              </div>
            </Flex>
          }
        />
      </Flex>
      <Button label={t("CONFIRM")} useNunitoFont width="100%" onClick={goToNextStep} />
    </Flex>
  );
};

export default ConnectivityComm;
