import Frame from "@/components/Frame/Frame";
import OrderedList from "@/components/OrderedList/OrderedList";
import { useTranslation } from "@/utils/translations/Translator";
import { StyledText, Types } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";

const EvseIdOrderedFrame = () => {
  const { t } = useTranslation();

  return (
    <Frame>
      <OrderedList
        items={[
          <StyledText
            key={1}
            text={t("PLEASE_ATTACH_THE_YELLOW_QR_STICKERS")}
            color={theme.colors.primaryBlack}
            type={Types.ns_16_sb}
          />,
          <StyledText
            key={2}
            text={t("SCAN_OR_ENTER_THE_INDIVIDUAL_EVSEID")}
            color={theme.colors.primaryBlack}
            type={Types.ns_16_sb}
          />
        ]}
      />
    </Frame>
  );
};

export default EvseIdOrderedFrame;
