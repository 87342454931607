import { useState } from "react";
import { EMAIL_REGEXP } from "@/utils/regexp";
import { TransKey } from "@/utils/helpers";

export const useEmailValidation = (initialValue?: string) => {
  const [email, setEmail] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState<TransKey | "">("");

  const validateEmail = ({ onSuccess }: { onSuccess: () => Promise<void> }) => {
    if (email === "") {
      setEmailErrorMessage("");
      return;
    }

    if (EMAIL_REGEXP.test(email)) {
      setEmailErrorMessage("");
      onSuccess();
    } else {
      setEmailErrorMessage("INVALID_EMAIL");
      setIsValid(false);
    }
  };

  const handleChange = (value: string) => {
    setEmail(value);
    setEmailErrorMessage("");
  };

  return {
    email,
    isValid,
    emailErrorMessage,
    handleChange,
    validateEmail
  };
};
