import { useTranslation } from "@/utils/translations/Translator";
import { Flex, List, Types } from "@emonvia/web-components";
import InfoFrame from "@/components/InfoFrame";
import ErrorFrame from "@/components/ErrorFrame";
import infoErrorIcon from "@/assets/images/infoErrorIcon.svg";
import { Trans } from "react-i18next";
import { StyledBlueLinkForTrans } from "@/components/TrackedComponents/reevComponents";
import { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { useSerialNumber } from "@/hooks/useSerialNumber";
import { CONFIGURATION_MANUAL_URL_BY_LANGUAGE } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";

const ConnectivityNotFound = () => {
  const { t } = useTranslation();
  const { serialNumber } = useSerialNumber();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <ErrorFrame
        icon={infoErrorIcon}
        title={t("POINT_NOT_FOUND")}
        subtitle={serialNumber}
        description={t("POINT_NOT_FOUND_HELP")}
      />
      <InfoFrame
        textType={Types.ns_16_m}
        description={
          <Flex gap={16} flexDirection="column">
            <span>{t("IMPORTANT_INFO_TO_GET_STARTED")}</span>
            <List>
              <li>
                <Trans
                  i18nKey="YOU_CAN_FIND_INFO_IN_HELP_CENTER"
                  components={[
                    <StyledBlueLinkForTrans
                      key="CONFIG_MANUAL"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(CONFIGURATION_MANUAL_URL_BY_LANGUAGE[getCurrentLanguage()])
                      }
                      target="_blank"
                    />
                  ]}
                />
              </li>
              <li>{t("FINISH_TROUBLESHOOTING_STEPS")}</li>
            </List>
          </Flex>
        }
      />
      <Button
        label={t("CONTINUE")}
        useNunitoFont
        width="100%"
        margin={[16, 0, 0, 0]}
        onClick={goToNextStep}
      />
    </Flex>
  );
};

export default ConnectivityNotFound;
