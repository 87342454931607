import { FC, PropsWithChildren, ReactNode } from "react";
import { FrameContainer } from "@/components/Frame/styles";
import { Flex, StyledText, Types } from "@emonvia/web-components";

type IProps = {
  header?: ReactNode | string;
};

const Frame: FC<PropsWithChildren<IProps>> = ({ children, header }) => {
  return (
    <FrameContainer data-testid={"Info_Frame"}>
      <Flex flexDirection="column" gap={12}>
        {header && <StyledText text={header} type={Types.ns_16_m} />}
        {children}
      </Flex>
    </FrameContainer>
  );
};

export default Frame;
