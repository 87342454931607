import { removeUndefinedAndNull } from "@emonvia/web-components/dist/utils/helpers";
import React, { useEffect, useContext, FC, PropsWithChildren } from "react";
import conf from "@/env";
import { getCurrentLanguage } from "@/utils/i18n";
import { InfrastructureType } from "@/utils/enums/InfrastructureType";
import { InstallationType } from "@/utils/enums/InstallationType";

declare const window: Window & {
  onUsersnapCXLoad: (api: GlobalApi) => void;
};
interface InitOptions {
  collectGeoLocation?: "all" | "none";
  custom?: Record<string, unknown>;
  locale?: string;
  nativeScreenshot?: boolean;
  useLocalStorage?: boolean;
  useSystemFonts?: boolean;
  user?: {
    email?: string;
    userId?: string;
  };
}

type EventName = "open" | "beforeSubmit" | "submit";

type ValueKey = "assignee" | "custom" | "labels" | "visitor";

interface BeforeSubmitEvent {
  apiKey: string;
  api: {
    setValue: <Key extends ValueKey>(key: Key, value: BeforeSubmitEvent["values"][Key]) => void;
  };
  values: {
    assignee?: string;
    custom?: Record<string, unknown>;
    labels?: string[];
    visitor: string;
  };
}

interface OpenEvent {
  apiKey: string;
  api: {
    setValue: (key: ValueKey, value: unknown) => void;
  };
}

type Events = {
  beforeSubmit: BeforeSubmitEvent;
  open: OpenEvent;
  submit: BeforeSubmitEvent;
};

interface GlobalApi {
  init: (options: InitOptions) => void;
  show: (key: string) => void;
  on: <T extends EventName>(eventName: T, callback: (event: Events[T]) => void) => void;
  off: <T extends EventName>(eventName: T, callback: (event: Events[T]) => void) => void;
  destroy: () => void;
}

const values: ISetUsersnapValues = {
  user: {
    email: ""
  },
  custom: {
    serialNumbers: null,
    onboardingId: null,
    infrastructureType: null,
    installationType: null
  }
};

type ISetUsersnapValues = {
  user: InitOptions["user"];
  custom: {
    serialNumbers: string;
    onboardingId: string;
    infrastructureType: InfrastructureType;
    installationType: InstallationType;
  };
};

type Label = "existing" | "new" | "bundle" | "reev connect" | "reev ready";

const LabelsByOnboardingCustomData: { [key in InfrastructureType | InstallationType]: Label } = {
  EXISTING_INFRASTRUCTURE: "existing",
  NEW_INFRASTRUCTURE: "new",
  BUNDLE: "bundle",
  REEV_CONNECT: "reev connect",
  REEV_READY: "reev ready"
};

const getLabels = (): string[] => {
  const { installationType, infrastructureType } = values.custom;
  const labels: string[] = [];
  labels.push(conf.stage);
  !!installationType && labels.push(LabelsByOnboardingCustomData[installationType]);
  !!infrastructureType && labels.push(LabelsByOnboardingCustomData[infrastructureType]);

  return labels;
};

export const setUserSnapValues = ({ user, custom }: ISetUsersnapValues) => {
  values.custom = removeUndefinedAndNull(custom);

  if (user.email) {
    values.user.email = user.email;
  }
};

export const UsersnapContext = React.createContext(null);

export const UsersnapProvider: FC<PropsWithChildren> = ({ children }) => {
  const currentLocale = getCurrentLanguage();

  useEffect(() => {
    if (!conf.usersnapGlobalApiKey || !conf.usersnapProjectKey) {
      return;
    }

    let usersnapApi: GlobalApi = null;

    window.onUsersnapCXLoad = function (api: GlobalApi) {
      api.init({
        locale: currentLocale
      });

      api.show(conf.usersnapProjectKey);
      usersnapApi = api;

      api.on("open", (event) => {
        if (values.user.email) {
          event.api.setValue("visitor", values.user.email);
        }
      });

      api.on("beforeSubmit", (event) => {
        event.api.setValue("labels", getLabels());
        event.api.setValue("custom", values.custom);
      });
    };

    const script = document.createElement("script");
    script.defer = true;
    script.src = `https://widget.usersnap.com/global/load/${conf.usersnapGlobalApiKey}?onload=onUsersnapCXLoad`;
    document.head.appendChild(script);

    return () => {
      if (usersnapApi) {
        usersnapApi.destroy();
      }
      script.remove();
    };
  }, []);

  return <UsersnapContext.Provider value>{children}</UsersnapContext.Provider>;
};

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
