import {
  StyledCheckMarkWrapper,
  StyledSendEmailButton,
  StyledValidatedTextWrapper
} from "@/components/SendEmailButton/styles";
import ValidatedTextInput, { IValidatedTextInputProps } from "@/components/ValidatedTextInput";
import { Flex, isEmpty } from "@emonvia/web-components";
import React, { FC, useEffect, useState } from "react";
import CheckMark from "@/assets/images/checkmark.svg";
import { useTranslation } from "@/utils/translations/Translator";

interface IProps extends IValidatedTextInputProps {
  dataTestid?: string;
  onButtonClick: (
    setIsSent: (isSent: boolean) => void,
    setIsDisabled: (isDisabled: boolean) => void
  ) => void;
  buttonLabel?: string;
  height?: number;
  disableButton?: boolean;
}

const SendEmailButton: FC<IProps> = ({
  onButtonClick,
  dataTestid,
  onChange,
  value,
  buttonLabel,
  height = 44,
  disableButton,
  ...inputProps
}) => {
  const { t } = useTranslation();
  const [sendClicked, setSendClicked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // Reset to 'send' when the agreeToEmail is changed
    if (disableButton || isEmpty(value)) {
      setSendClicked(false);
    }
  }, [value, disableButton]);
  const handleButtonClick = async () => {
    if (sendClicked) return;
    onButtonClick(setSendClicked, setDisabled);
  };

  return (
    <Flex flexDirection="row">
      <StyledValidatedTextInput
        data-testid="Email_Input"
        placeholder={t("ENTER_EMAIL")}
        value={value}
        onChange={onChange}
        hideEndElement
        height={height}
        {...inputProps}
      />
      <StyledSendEmailButton
        data-testid={dataTestid}
        label={
          (
            <StyledCheckMarkWrapper>
              {sendClicked ? (
                <>
                  <img width={16} height={16} src={CheckMark} alt="" />
                  {t("SENT")}
                </>
              ) : (
                buttonLabel || t("SEND")
              )}
            </StyledCheckMarkWrapper>
          ) as unknown as string
        }
        classNames={sendClicked && "success"}
        look="secondary"
        useNunitoFont
        onClick={handleButtonClick}
        disabled={disabled || disableButton || isEmpty(value)}
        style={{
          height
        }}
      />
    </Flex>
  );
};

const StyledValidatedTextInput: React.FC<Partial<IProps>> = (props: IProps) => {
  const { height } = props;
  return (
    <StyledValidatedTextWrapper height={height}>
      <ValidatedTextInput {...props} />
    </StyledValidatedTextWrapper>
  );
};

export default SendEmailButton;
