import styled from "styled-components";
import { Flex, IconButton, StyledText } from "@emonvia/web-components";

export const StyledOptionsContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.frameBodyBackground};
  padding: 20px;
  border-radius: 10px;
`;

export const StyledDivider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colors.grey100};
`;

export const StyledBadge = styled(StyledText)`
  padding: 3px 9px;
  color: ${(props) => props.theme.colors.success800};
  background: ${(props) => props.theme.colors.success100};
  border: 1px solid ${(props) => props.theme.colors.success200};
  border-radius: 6px;
`;

export const StyledIconButton = styled(IconButton)<{ clicked: boolean }>`
  border-color: ${({ theme }) => theme.colors.grey500};
  background-color: ${({ clicked, theme }) => (clicked ? theme.colors.success100 : "transparent")};

  i {
    color: ${({ clicked, theme }) => (clicked ? theme.colors.success800 : theme.colors.grey500)};
  }

  :hover:not(:disabled) {
    background: ${({ theme }) => theme.colors.success100};
    i {
      color: ${({ clicked, theme }) => (clicked ? theme.colors.success800 : theme.colors.grey700)};
    }
  }
`;
