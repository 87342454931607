/* eslint-disable @typescript-eslint/no-explicit-any */

type TWindow = Window & {
  appConfig: {
    ENV: string;
    ROOT_URL: string;
    API_URL: string;
    POSTHOG_API_KEY: string;
    POSTHOG_API_HOST: string;
    USERSNAP_GLOBAL_API_KEY: string;
    USERSNAP_PROJECT_KEY: string;
    SENTRY_DSN?: string;
    DEBUG_MODE?: boolean;
    SENTRY_SAMPLE_RATE: number;
    BETTER_STACK_PROJECT_ID?: string;
    BETTER_STACK_SCRIPT_URL: string;
  };
};

declare const window: TWindow;

const version = __VERSION__;

const conf = {
  rootUrl: window.appConfig.ROOT_URL,
  apiUrl:
    typeof import.meta.env.VITE_API_URL !== "undefined"
      ? import.meta.env.VITE_API_URL
      : window.appConfig.API_URL,
  version: version,
  stage: window.appConfig.ENV,
  debugMode: window.appConfig.DEBUG_MODE,
  postHogApiKey: window.appConfig.POSTHOG_API_KEY,
  postHogApiHost: window.appConfig.POSTHOG_API_HOST,
  sentryDsn: window.appConfig.SENTRY_DSN,
  usersnapGlobalApiKey: window.appConfig.USERSNAP_GLOBAL_API_KEY,
  usersnapProjectKey: window.appConfig.USERSNAP_PROJECT_KEY,
  sentryTraceSampleRate: window.appConfig.SENTRY_SAMPLE_RATE,
  betterStackScriptUrl: window.appConfig.BETTER_STACK_SCRIPT_URL,
  betterStackProjectId: window.appConfig.BETTER_STACK_PROJECT_ID
};

export default conf;
