import React, { useMemo } from "react";

import BackArrowButton from "@/assets/images/backArrowButton.svg";
import ReevLogo from "@/assets/images/reev-logo.svg";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";
import AllRoutes, {
  BACK_STEP_TO_ONBOARDING_STEP,
  goToPreviousStep,
  Path
} from "@/routing/AllRoutes";
import LanguageSwitch from "@/components/LanguageSwitch";
import { useLocation } from "react-router-dom";
import { TransKey } from "@/utils/translations/Translator";
import { useTranslation } from "react-i18next";
import { StyledHeader } from "@/components/Header/styles";
import { useTracking } from "@/context/TrackingContext";
import PostHogEvents from "@/utils/postHogEvents";
import { useOnboarding } from "@/hooks/useOnboarding";
import Storage from "@/utils/Storage";
import { ABL_SERIAL_NUMBER, CPO_ONBOARDING_ID } from "@/utils/constants";
import { useQueryParams } from "@/hooks/useQueryParams";

type THeading = {
  [key in Path]: {
    heading: TransKey | "default";
  };
};

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { data, update } = useOnboarding();
  const { pathname } = useLocation();
  const { removeQueryParam } = useQueryParams();

  const path = pathname as Path;

  const shouldShowBackButton = !([AllRoutes.ROOT, AllRoutes.CONFIG_WIZARD_ROOT] as Path[]).includes(
    path
  );

  const RouteTitle: THeading = useMemo(
    () => ({
      [AllRoutes.ROOT]: { heading: "default" },
      [AllRoutes.CONFIG_WIZARD_ROOT]: { heading: "default" },
      [AllRoutes.CONNECTIVITY_CHECK]: { heading: "CONNECTIVITY_STATUS" },
      [AllRoutes.CONNECTIVITY_NO_FOUND]: { heading: "CONNECTIVITY_STATUS" },
      [AllRoutes.CONNECTIVITY_TROUBLESHOOT_GRID]: { heading: "CONNECTION_TO_GRID" },
      [AllRoutes.CONNECTIVITY_TROUBLESHOOT_BE]: { heading: "BACKEND_SETTINGS" },
      [AllRoutes.CONNECTIVITY_TROUBLESHOOT_COMM]: { heading: "COMMUNICATION_SETTINGS" },
      [AllRoutes.CONNECTIVITY_TROUBLESHOOT_FW]: { heading: "FIRMWARE_UPDATE" },
      [AllRoutes.CONNECTIVITY_TROUBLESHOOT_REBOOT]: { heading: "RESET" },
      [AllRoutes.CONNECTIVITY_TROUBLESHOOT_DONE]: { heading: "CONNECTIVITY_STATUS" },
      [AllRoutes.CONNECTIVITY_TROUBLESHOOT_UNSUCCESSFUL]: { heading: "CONNECTIVITY_STATUS" },
      [AllRoutes.CONNECTIVITY_PRE_CONFIG_OFFLINE]: { heading: "CONNECTIVITY_STATUS" },
      [AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_FW_RETRY]: {
        heading: "CONNECTIVITY_STATUS"
      },
      [AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_GRID]: { heading: "CONNECTION_TO_GRID" },
      [AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_BE]: { heading: "BACKEND_SETTINGS" },
      [AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_COMM]: { heading: "COMMUNICATION_SETTINGS" },
      [AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_REBOOT]: { heading: "RESET" },
      [AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_DONE]: { heading: "CONNECTIVITY_STATUS" },
      [AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_UNSUCCESSFUL]: {
        heading: "CONNECTIVITY_STATUS"
      },
      [AllRoutes.CONNECTIVITY_STATUS]: { heading: "CONNECTIVITY_STATUS" },
      [AllRoutes.CONNECTORS_STATUS]: { heading: "CONNECTOR_STATUS" },
      [AllRoutes.CONNECTOR_EVSEID]: { heading: "CONNECTOR_EVSEID" },
      [AllRoutes.ADD_INFO]: { heading: "ADDITIONAL_INFO" },
      [AllRoutes.CONNECTORS_TROUBLESHOOT_GROUPING]: { heading: "CONNECTOR_STATUS" },
      [AllRoutes.CONNECTORS_TROUBLESHOOT_AVAILABLE]: { heading: "CONNECTOR_STATUS" },
      [AllRoutes.CONNECTORS_TROUBLESHOOT_FAILED]: { heading: "CONNECTOR_STATUS" },
      [AllRoutes.INSTALLATION_REPORT]: { heading: "INSTALLATION_REPORT" },
      [AllRoutes.EMAIL_NEWSLETTER]: { heading: "REEV_NEWSLETTER" }
    }),
    [pathname]
  );

  const goBackWithTracking = useTracking({
    callback: goToPreviousStep,
    eventMessage: PostHogEvents.backButtonClick
  });

  const goBackWithUpdate = async () => {
    const currentPath = location.pathname;
    if (currentPath === AllRoutes.CONNECTIVITY_CHECK) {
      Storage.remove(CPO_ONBOARDING_ID);
    }
    if (currentPath === AllRoutes.CONNECTIVITY_STATUS) {
      removeQueryParam(ABL_SERIAL_NUMBER);
    }
    if (BACK_STEP_TO_ONBOARDING_STEP[currentPath as Path]) {
      await update({
        chargePoint: data.chargePoint,
        state: BACK_STEP_TO_ONBOARDING_STEP[currentPath as Path]
      });
    }

    await goBackWithTracking();
  };

  const { heading } = RouteTitle[path] || { heading: "default" };

  return (
    <StyledHeader>
      <Flex
        flexDirection="row"
        style={{
          width: "100%",
          maxWidth: "495px",
          height: "100%",
          position: "relative",
          alignItems: "flex-end"
        }}
        justifyContent="center"
      >
        {!shouldShowBackButton && (
          <Flex flexDirection="column" style={{ justifyContent: "center", alignItems: "center" }}>
            <img id="reev-logo" src={ReevLogo} width={112} />
          </Flex>
        )}

        {shouldShowBackButton && (
          <>
            <Flex
              style={{ position: "absolute", left: 0, height: "100%", cursor: "pointer" }}
              alignItems="flex-end"
            >
              <img onClick={goBackWithUpdate} src={BackArrowButton} width={24} height={24} />
            </Flex>
            <Flex
              flexDirection="column"
              gap={10}
              flexGrow={2}
              justifyContent="center"
              alignItems="center"
            >
              {heading ? (
                <StyledText
                  text={t(heading)}
                  type={Types.ns_18_sb}
                  color={theme.colors.primaryWhite}
                />
              ) : (
                <img id="reev-logo" src={ReevLogo} width={112} height={42} />
              )}
            </Flex>
          </>
        )}
      </Flex>

      <Flex style={{ position: "absolute", right: 24, bottom: 28 }}>
        <LanguageSwitch />
      </Flex>
    </StyledHeader>
  );
};

export default Header;
