import styled, { keyframes } from "styled-components";
import { StyledText } from "@emonvia/web-components";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const AnimatedStyledText = styled(StyledText)`
  opacity: 0;
  animation: ${fadeInAnimation} 1s forwards;
  animation-delay: 0.6s;
`;
