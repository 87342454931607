import { useLocation, useNavigate } from "react-router-dom";
import { ABL_SERIAL_NUMBER, CPO_ONBOARDING_ID, GROUP_LIMIT } from "@/utils/constants";
import { useEffect } from "react";
import Storage from "@/utils/Storage";

export const useQueryParams = (isTroubleshoot = false) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);

  const serial = params.get(ABL_SERIAL_NUMBER) || "";
  const groupLimit = params.get(GROUP_LIMIT) || "";

  // Check if both serial and groupLimit exist and are non-empty
  const areQueryParamsPresent: boolean = !!serial && !!groupLimit;

  const onboardingId = Storage.get(CPO_ONBOARDING_ID);

  useEffect(() => {
    if (areQueryParamsPresent && onboardingId && !isTroubleshoot) {
      Storage.remove(CPO_ONBOARDING_ID); // remove id if already there to restart
    }
  }, []);

  const removeQueryParam = (paramName: string) => {
    const params = new URLSearchParams(search);

    // Remove the parameter
    params.delete(paramName);

    // Update the URL with the modified query parameters
    navigate(`${pathname}?${params.toString()}`, { replace: true });
  };

  return { serial, groupLimit, areQueryParamsPresent, removeQueryParam };
};
