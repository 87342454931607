import { createContext, FC, PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import {
  IGetChargePointResponse,
  IGetChargePointResponseData_IGetChargePointResponse
} from "@/api/ApiTypes";
import { IGetChargePointResponse_ChargePointStatus } from "@/api/Enums";
import ReevApiCalls from "@/api/ReevApiCalls";
import { isNotEmpty, useDebounce } from "@emonvia/web-components";
import { SERIAL_NUMBER_REGEXP } from "@/utils/regexp";
import { TransKey } from "@/utils/translations/Translator";
import { useQueryParams } from "@/hooks/useQueryParams";
import { ABL_SERIAL_NUMBER } from "@/utils/constants";

export enum ESerialNumberErrorStatus {
  "OFFLINE" = "OFFLINE",
  "NOT_FOUND" = "NOT_FOUND",
  "ALREADY_ONBOARDED" = "ALREADY_ONBOARDED"
}

interface IUseSerialNumberReturn {
  serialNumber: string;
  serialNumberValid: boolean;
  serialNumberInvalid: boolean;
  serialNumberErrorMessage: TransKey | "";
  chargePointData: IGetChargePointResponse;
  onSerialNumberChange: (value: string) => void;
  errorStatus?: ESerialNumberErrorStatus;
  findChargePoint: () => Promise<IGetChargePointResponseData_IGetChargePointResponse>;
  isLoading: boolean;
}

const SerialNumberContext = createContext<IUseSerialNumberReturn>({
  serialNumber: "",
  serialNumberValid: null,
  serialNumberInvalid: null,
  serialNumberErrorMessage: "",
  chargePointData: null,
  onSerialNumberChange: () => undefined,
  errorStatus: null,
  findChargePoint: () => null,
  isLoading: false
});

export const SerialNumberProvider: FC<PropsWithChildren> = ({ children }) => {
  const [serialNumber, setSerialNumber] = useState("");
  const [chargePointData, setChargePointData] =
    useState<IGetChargePointResponseData_IGetChargePointResponse>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [serialNumberValid, setSerialNumberValid] = useState<boolean>(null);
  const [serialNumberErrorMessage, setSerialNumberErrorMessage] = useState<TransKey | "">("");
  const [errorStatus, setErrorStatus] = useState<ESerialNumberErrorStatus>();

  const debouncedSerialNumber = useDebounce(serialNumber, 700);
  const { serial: paramSerial, removeQueryParam } = useQueryParams();

  useEffect(() => {
    if (paramSerial) {
      onSerialNumberChange(paramSerial);
    }
  }, [paramSerial]);

  const onSerialNumberChange = (serialNumber: string) => {
    setSerialNumber(serialNumber);
    if (serialNumber !== paramSerial) {
      removeQueryParam(ABL_SERIAL_NUMBER);
    }
  };

  const findChargePointBySerialNumber = async () => {
    if (!SERIAL_NUMBER_REGEXP.test(debouncedSerialNumber)) {
      setSerialNumberValid(false);
      setSerialNumberErrorMessage("INVALID_SERIAL_NUMBER");
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await ReevApiCalls.getChargePointBySerialNumber({
        pathParams: { serialNumber: debouncedSerialNumber }
      });
      setChargePointData(data);

      if (data?.chargePointStatus === IGetChargePointResponse_ChargePointStatus.ONLINE) {
        setSerialNumberValid(true);

        return data;
      }

      if (
        data?.chargePointStatus === IGetChargePointResponse_ChargePointStatus.OFFLINE ||
        data?.chargePointStatus === IGetChargePointResponse_ChargePointStatus.NOT_AVAILABLE
      ) {
        setErrorStatus(ESerialNumberErrorStatus.OFFLINE);
        setSerialNumberErrorMessage("CHARGE_POINT_OFFLINE");
        setSerialNumberValid(false);

        return data;
      }
    } catch (error) {
      setSerialNumberValid(false);

      if (error.status === 404) {
        setErrorStatus(ESerialNumberErrorStatus.NOT_FOUND);
        setSerialNumberErrorMessage("CHARGE_POINT_NOT_FOUND");

        return;
      }

      if (error.status === 403) {
        setErrorStatus(ESerialNumberErrorStatus.ALREADY_ONBOARDED);
        setSerialNumberErrorMessage("CHARGE_POINT_ALREADY_ONBOARDED");

        return;
      }

      setSerialNumberErrorMessage("UNEXPECTED_ERROR_DETECTED");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const findChargePointHandler = () => {
    setSerialNumberValid(null);
    setErrorStatus(undefined);
    setSerialNumberErrorMessage("");

    if (isNotEmpty(debouncedSerialNumber)) {
      return findChargePointBySerialNumber();
    } else {
      setSerialNumberValid(null);
    }
  };

  useEffect(() => {
    findChargePointHandler();
  }, [debouncedSerialNumber]);

  return (
    <SerialNumberContext.Provider
      value={{
        serialNumber,
        serialNumberValid,
        serialNumberErrorMessage,
        serialNumberInvalid: !isLoading && serialNumberValid === false,
        onSerialNumberChange,
        chargePointData: chargePointData as IGetChargePointResponse,
        errorStatus,
        findChargePoint: findChargePointHandler,
        isLoading
      }}
    >
      {children}
    </SerialNumberContext.Provider>
  );
};

export default SerialNumberContext;
