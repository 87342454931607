import React, { useEffect, useRef } from "react";
import { TextInput } from "@emonvia/web-components";
import Completed from "@/assets/images/completed.svg";
import AlertTriangle from "@/assets/images/alert-triangle.svg";
import Edit from "@/assets/images/edit.svg";

export const CheckMarkElement = (
  <img
    src={Completed}
    width={18}
    height={18}
    alt="check icon"
    data-testid="ValidatedTextInput_CheckIcon"
  />
);

const AlertElement = <img src={AlertTriangle} width={18} height={18} alt="alert icon" />;

type TextInputProps = React.ComponentProps<typeof TextInput>;

export type IValidatedTextInputProps = TextInputProps & {
  "data-testid"?: string;
  onChange?: (val: string) => void;
  errorMessage?: React.ReactNode | string;
  valid?: boolean;
  hideEndElement?: boolean;
  cameraIcon?: React.ReactNode | string;
};

const getEndElement = (
  valid: boolean,
  errorMessage: React.ReactNode | string,
  cameraIcon?: React.ReactNode | string
) => {
  const inputIsValid = valid;
  const errorMessageWasGiven = !!errorMessage;

  return (
    <>
      {cameraIcon}
      {inputIsValid ? (
        CheckMarkElement
      ) : errorMessageWasGiven ? (
        AlertElement
      ) : (
        <img src={Edit} width={18} height={18} alt="edit icon" />
      )}
    </>
  );
};

const ValidatedTextInput: React.FC<IValidatedTextInputProps> = (props) => {
  const { valid = false, errorMessage, onChange, hideEndElement, cameraIcon } = props;
  const initialLoad = useRef(true);
  const userHasInteracted = useRef(false);

  useEffect(() => {
    initialLoad.current = false;
  }, []);

  const onChangeWithTracking = (val: string) => {
    if (onChange) {
      onChange(val);
    }

    if (!initialLoad.current) {
      userHasInteracted.current = true;
    }
  };

  return (
    <TextInput
      {...props}
      onChange={onChangeWithTracking}
      endElement={!hideEndElement && getEndElement(valid, errorMessage, cameraIcon)}
      look="secondary"
      asteriskColor="secondary"
    />
  );
};

export default ValidatedTextInput;
