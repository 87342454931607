import { useState, useEffect } from "react";
import { useDebounce } from "@emonvia/web-components";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useOnboarding } from "@/hooks/useOnboarding";
import { goToNextStep, NEXT_STEP_TO_ONBOARDING_STEP, Path } from "@/routing/AllRoutes";
import { TransKey } from "@/utils/translations/Translator";
import { useQueryParams } from "@/hooks/useQueryParams";
import { GROUP_LIMIT } from "@/utils/constants";

const errorMessagesByStatus: { [key: number]: TransKey } = {
  400: "INVALID_ACTIVATION_CODE",
  412: "ACTIVATION_CODE_IS_ALREADY_ASSIGNED",
  404: "ACTIVATION_CODE_NOT_FOUND"
};

const useAdditionalInfoForm = () => {
  const { data, update, isLoading } = useOnboarding();
  const [maxLoad, setMaxLoad] = useState("");
  const [activationCode, setActivationCode] = useState("");
  const [activationCodeExists, setActivationCodeExists] = useState(null);
  const [errorMessage, setErrorMessage] = useState<TransKey | "">("");
  const [maxLoadInvalid, setMaxLoadInvalid] = useState(null);

  const { removeQueryParam, groupLimit } = useQueryParams();

  const debouncedActivationCode = useDebounce(activationCode, 700);

  useEffect(() => {
    if (data?.chargePoint?.activationCode) {
      setActivationCode(data.chargePoint.activationCode);
    }
    if (data?.chargePoint?.maxLoadInKW) {
      setMaxLoad(data.chargePoint.maxLoadInKW.toString());
    }
  }, [data]);

  useEffect(() => {
    verifyActivationCode();
  }, [debouncedActivationCode]);

  const verifyActivationCode = async () => {
    if (!debouncedActivationCode) return;

    // remove first dot to also accept format {4 chars}.{4 chars}
    const debouncedActivationCodeWithoutDot = debouncedActivationCode.replace(".", "");

    try {
      await ReevApiCalls.verifyActivationCode({
        pathParams: { activationCode: debouncedActivationCodeWithoutDot }
      });
      setActivationCodeExists(true);
      setErrorMessage("");
    } catch (error) {
      setActivationCodeExists(false);
      setErrorMessage(errorMessagesByStatus[error.status] || "UNKNOWN_ERROR");
    }
  };

  const handleActivationCodeChange = (value: string) => {
    setActivationCode(value);
    setActivationCodeExists(null);
    setErrorMessage(null);
  };

  useEffect(() => {
    if (groupLimit) {
      handleMaxLoadChange(groupLimit);
    }
  }, [groupLimit]);

  const handleMaxLoadChange = (value: string) => {
    if (value === "0") {
      setMaxLoadInvalid(true);
    } else {
      setMaxLoadInvalid(false);
    }
    setMaxLoad(value);
    if (value !== groupLimit) {
      removeQueryParam(GROUP_LIMIT);
    }
  };

  const updateAdditionalInfo = async () => {
    const currentPath = location.pathname;
    const activationCodeWithoutDot = activationCode ? activationCode.replace(".", "") : null;
    await update({
      chargePoint: {
        ...data.chargePoint,
        activationCode: activationCodeWithoutDot,
        maxLoadInKW: maxLoad ? Number(maxLoad) : null
      },
      state: NEXT_STEP_TO_ONBOARDING_STEP[currentPath as Path]
    });
    await goToNextStep();
  };

  return {
    activationCode,
    maxLoad,
    activationCodeExists,
    maxLoadInvalid,
    errorMessage,
    handleActivationCodeChange,
    handleMaxLoadChange,
    goToNext: updateAdditionalInfo,
    isLoading
  };
};

export default useAdditionalInfoForm;
