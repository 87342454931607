import { Frame } from "@emonvia/web-components";
import styled from "styled-components";

export const FrameContainer = styled(Frame)`
  background-color: ${({ theme }) => theme.colors.frameBodyBackground};
  box-shadow: none;
  width: 100%;
  border-radius: 10px;
  padding: 0;
`;
