import React, { useState } from "react";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import InfoFrame from "@/components/InfoFrame";
import ChargePointInput from "@steps/ConnectivityCheck/components/ChargePointInput";
import { Button, StyledBlueLinkForTrans } from "@/components/TrackedComponents/reevComponents";
import allRoutes, { navigateWithQuery } from "@/routing/AllRoutes";
import { ESerialNumberErrorStatus } from "@/context/SerialNumberContext";
import { Trans } from "react-i18next";
import { HELP_CENTRE } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";
import theme from "@/utils/theme/theme";

const ConnectivityCheck: React.FC = () => {
  const { t } = useTranslation();

  const [errorStatus, setErrorStatus] = useState<ESerialNumberErrorStatus>();

  return (
    <Flex
      data-testid="ConnectivityCheck_Container"
      flexDirection="column"
      justifyContent="center"
      gap={16}
    >
      <ChargePointInput onErrorStatus={setErrorStatus} />
      <InfoFrame
        description={t("CHARGEPOINT_SERIAL_NUMBER_USES_OCPP_WITH_BE")}
        linkText={
          <StyledText
            data-testid={"linkText_TextLink"}
            text={
              <Trans
                i18nKey={t("FIND_MORE_INFORMATION_HERE")}
                components={[
                  <StyledBlueLinkForTrans
                    key={t("HERE") + "link"}
                    style={{ cursor: "pointer" }}
                    onClick={() => window.open(HELP_CENTRE[getCurrentLanguage()])}
                    type={Types.ns_16_m}
                    target="_blank"
                  />
                ]}
              />
            }
            type={Types.ns_16_m}
            margin={[20, 0, 0]}
            color={theme.colors.grey750}
          />
        }
        textType={Types.ns_16_m}
      />
      {errorStatus === ESerialNumberErrorStatus.NOT_FOUND && (
        <Button
          data-testid="ConnectivityCheck_StartTroubleshootingNotFoundBtn"
          label={t("START_TROUBLESHOOTING")}
          useNunitoFont
          width="100%"
          margin={[32, 0, 0, 0]}
          onClick={() => navigateWithQuery(allRoutes.CONNECTIVITY_NO_FOUND)}
        />
      )}
      {errorStatus === ESerialNumberErrorStatus.OFFLINE && (
        <Button
          data-testid="ConnectivityCheck_StartTroubleshootingOfflineBtn"
          label={t("START_TROUBLESHOOTING")}
          useNunitoFont
          width="100%"
          margin={[32, 0, 0, 0]}
          onClick={() => navigateWithQuery(allRoutes.CONNECTIVITY_PRE_CONFIG_OFFLINE)}
        />
      )}
    </Flex>
  );
};

export default ConnectivityCheck;
