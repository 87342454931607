import { useTranslation } from "@/utils/translations/Translator";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import handsImage from "@/assets/images/illustration-hands.svg";
import InfoFrame from "@/components/InfoFrame";
import SuccessFrame from "@/components/SuccessFrame";
import AllRoutes, { goToNextStep, navigateWithQuery } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { useSerialNumber } from "@/hooks/useSerialNumber";
import { IGetChargePointResponse_ChargePointStatus } from "@/api/Enums";
import allRoutes from "@/routing/AllRoutes";
import { useOnboarding } from "@/hooks/useOnboarding";
import { useQueryParams } from "@/hooks/useQueryParams";

const ConnectivityDone = () => {
  const { t } = useTranslation();
  const { update } = useOnboarding();
  const { findChargePoint, isLoading, onSerialNumberChange } = useSerialNumber();
  const { serial: paramSerial } = useQueryParams(true);

  const retryHandler = () =>
    findChargePoint()
      .then(async (chargePoint) => {
        if (
          !chargePoint ||
          chargePoint.chargePointStatus !== IGetChargePointResponse_ChargePointStatus.ONLINE
        ) {
          goToNextStep();
          return;
        }

        await navigateWithQuery(allRoutes.CONNECTIVITY_STATUS);
        await update({ chargePoint, state: AllRoutes.CONNECTIVITY_STATUS });
      })
      .catch((error) => {
        if (error.status === 404 || error.status === 403) {
          goToNextStep();
        }
      })
      .finally(() => onSerialNumberChange(paramSerial || ""));

  return (
    <Flex flexDirection="column" justifyContent="center" gap={32}>
      <Flex justifyContent="center">
        <img width={75} height={82} src={handsImage} />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <SuccessFrame
          description={<StyledText text={t("GREAT_JOB_NOW_RETRY")} type={Types.ns_16_sb} />}
        />
        <InfoFrame description={t("RETRY_ONLY_AFTER_REBOOT")} />
      </Flex>
      <Button
        label={t("RETRY")}
        useNunitoFont
        disabled={isLoading}
        width="100%"
        onClick={retryHandler}
      />
    </Flex>
  );
};

export default ConnectivityDone;
