import { useTranslation } from "@/utils/translations/Translator";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import ProgressBar, { IProgressBar } from "@/components/ProgressBar/ProgressBar";
import updateImage from "@/assets/images/illustration-update.svg";
import GuideTextFrame from "@/components/GuideTextFrame";
import InfoFrame from "@/components/InfoFrame";
import infoQuestionIcon from "@/assets/images/infoQuestionIcon.svg";
import { Trans } from "react-i18next";
import { FC } from "react";
import { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";

const ConnectivityFw: FC<IProgressBar> = (props) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={32}>
      <ProgressBar {...props} />
      <Flex justifyContent="center">
        <img width={116} height={82} src={updateImage} />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <GuideTextFrame
          description={<StyledText text={t("CHECK_FIRMWARE_VERSION")} type={Types.ns_16_sb} />}
        />
        <InfoFrame
          textType={Types.ns_16_m}
          icon={infoQuestionIcon}
          description={<Trans i18nKey="FIRMWARE_QUESTIONS" />}
        />
      </Flex>
      <Button label={t("CONFIRM")} useNunitoFont width="100%" onClick={goToNextStep} />
    </Flex>
  );
};

export default ConnectivityFw;
