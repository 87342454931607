export enum IOnboardingResponse_Language {
  EN = "EN",
  DE = "DE",
  IT = "IT",
  FR = "FR",
  NL = "NL"
}

export enum IGetChargePointResponse_ConnectionType {
  LTE = "LTE",
  LAN = "LAN"
}

export enum IGetChargePointResponse_ChargePointStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  NOT_AVAILABLE = "NOT_AVAILABLE"
}

export enum IOnboardingConnector_Status {
  AVAILABLE = "AVAILABLE",
  PREPARING = "PREPARING",
  CHARGING = "CHARGING",
  SUSPENDED_EV = "SUSPENDED_EV",
  SUSPENDED_EVSE = "SUSPENDED_EVSE",
  FINISHING = "FINISHING",
  RESERVED = "RESERVED",
  FAULTED = "FAULTED",
  UNAVAILABLE = "UNAVAILABLE",
  OFFLINE = "OFFLINE"
}
