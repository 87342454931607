import { WhiteListedLanguage } from "@/utils/i18n";

type TStringByLanguage = {
  [key in WhiteListedLanguage]: string;
};

export const CPO_ONBOARDING_ID = "cpoOnboardingId";
export const ONBOARDING_START_TIME = "ONBOARDING_START_TIME";
export const AGREED_TO_ANALYTICS_CONSENT = "agreedToAnalyticsConsent";
export const ACCEPTED = "ACCEPTED";
export const REJECTED = "REJECTED";
export const ABL_SERIAL_NUMBER = "serial";
export const GROUP_LIMIT = "groupLimit";

export const PRIVACY_POLICY_URL_BY_LANGUAGE: TStringByLanguage = {
  en: "https://reev.com/en/privacy-policy/",
  de: "https://reev.com/datenschutz/",
  fr: "https://reev.com/fr/politique-de-confidentialite/",
  it: "https://reev.com/it/informativa-sulla-privacy/",
  nl: "https://reev.com/nl/privacybeleid/"
} as const;

const CONFIGURATION_MANUAL_EN =
  "https://reevsupport.freshdesk.com/en/support/solutions/60000088240";
export const CONFIGURATION_MANUAL_URL_BY_LANGUAGE: TStringByLanguage = {
  en: CONFIGURATION_MANUAL_EN,
  de: "https://reevsupport.freshdesk.com/de/support/solutions/60000088240",
  nl: "https://reevsupport.freshdesk.com/nl/support/solutions/60000088240",
  // defaults to en
  fr: CONFIGURATION_MANUAL_EN,
  it: CONFIGURATION_MANUAL_EN
};

export const CONTACT_URL_BY_LANGUAGE: TStringByLanguage = {
  en: "https://reev.com/en/contact/",
  de: "https://reev.com/kontakt/",
  fr: "https://reev.com/fr/contacter/",
  it: "https://reev.com/it/contatto/",
  nl: "https://reev.com/nl/contact/"
};

const LATEST_FIRMWARE_URL_EN = "https://www.ablmobility.de/en/service/downloads.php";
export const LATEST_FIRMWARE_URL_BY_LANGUAGE: TStringByLanguage = {
  en: LATEST_FIRMWARE_URL_EN,
  de: "https://www.ablmobility.de/de/service/downloads.php",
  nl: "https://www.ablmobility.de/nl/dienst/downloads.php",
  // defaults to en
  fr: LATEST_FIRMWARE_URL_EN,
  it: LATEST_FIRMWARE_URL_EN
};

const HELP_CENTRE_EN =
  "https://reevsupport.freshdesk.com/en/support/solutions/articles/60000862250";
export const HELP_CENTRE = {
  en: HELP_CENTRE_EN,
  de: "https://reevsupport.freshdesk.com/de/support/solutions/articles/60000862250-wo-finde-ich-die-seriennummer-einer-ladestation",
  // defaults to en
  fr: HELP_CENTRE_EN,
  it: HELP_CENTRE_EN,
  nl: HELP_CENTRE_EN
};

const ACTIVATION_CODE_EN =
  "https://reevsupport.freshdesk.com/en/support/solutions/articles/60001123761";
export const ACTIVATION_CODE = {
  //TODO: en link should be changed
  en: ACTIVATION_CODE_EN,
  de: "https://reevsupport.freshdesk.com/de/support/solutions/articles/60001123761",
  // defaults to en
  fr: ACTIVATION_CODE_EN,
  it: ACTIVATION_CODE_EN,
  nl: ACTIVATION_CODE_EN
};

const ONBOARDING_REEV_CONNECT_EN =
  "https://reevsupport.freshdesk.com/en/support/solutions/articles/60001013529-onboarding-reev-connect-explained";
export const ONBOARDING_REEV_CONNECT = {
  en: ONBOARDING_REEV_CONNECT_EN,
  de: "https://reevsupport.freshdesk.com/de/support/solutions/articles/60001013529-inbetriebnahme-i-reev-connect-erkl%C3%A4rt",
  // defaults to en
  fr: ONBOARDING_REEV_CONNECT_EN,
  it: ONBOARDING_REEV_CONNECT_EN,
  nl: ONBOARDING_REEV_CONNECT_EN
};
