import env from "@/env";
import { useEffect } from "react";

export const useBetterStackAnnouncement = () => {
  const { betterStackProjectId, betterStackScriptUrl } = env;

  useEffect(() => {
    if (betterStackProjectId) {
      const betterStackAnnouncementScript = document.createElement("script");
      betterStackAnnouncementScript.src = betterStackScriptUrl;
      betterStackAnnouncementScript.dataset.id = betterStackProjectId;
      betterStackAnnouncementScript.async = true;
      document.body.appendChild(betterStackAnnouncementScript);

      return () => {
        document.body.removeChild(betterStackAnnouncementScript);
      };
    }
  }, [betterStackProjectId]);
};
