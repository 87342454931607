import { FC } from "react";
import { Flex, Types } from "@emonvia/web-components";
import copyButtonIcon from "@/assets/images/copy-button.svg";
import { StyledCopyText, StyledLinkButton } from "./styles";
import theme from "@/utils/theme/theme";

interface ICopyButtonWithText {
  text: string;
}
const CopyButtonWithText: FC<ICopyButtonWithText> = ({ text }) => {
  const copyHandler = () => {
    if (!text || text.trim() === "") return;

    navigator.clipboard.writeText(text);
  };

  return (
    <Flex gap={12}>
      <StyledLinkButton onClick={copyHandler}>
        <img src={copyButtonIcon} height={24} width={24} alt={"copy-button"} />
      </StyledLinkButton>
      <StyledCopyText text={text} type={Types.ns_16_sb} color={theme.colors.blue500} />
    </Flex>
  );
};

export default CopyButtonWithText;
