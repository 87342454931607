import { useTranslation } from "@/utils/translations/Translator";
import { Flex, List, StyledText, Types } from "@emonvia/web-components";
import InfoFrame from "@/components/InfoFrame";
import ErrorFrame from "@/components/ErrorFrame";
import { Trans } from "react-i18next";
import { StyledBlueLinkForTrans } from "@/components/TrackedComponents/reevComponents";
import { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { CONFIGURATION_MANUAL_URL_BY_LANGUAGE } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";
import { useOnboarding } from "@/hooks/useOnboarding";
import { useSerialNumber } from "@/hooks/useSerialNumber";
import { useEffect, useRef } from "react";

const ConnectorsGrouping = () => {
  const { t } = useTranslation();
  const { data: onboardingData, update } = useOnboarding();
  const {
    chargePointData,
    isLoading,
    onSerialNumberChange,
    serialNumberValid,
    findChargePoint,
    serialNumber
  } = useSerialNumber();

  const userActionRef = useRef(false);

  const getFreshChargePointHandler = async () => {
    if (serialNumber) await findChargePoint();
    else onSerialNumberChange(onboardingData.chargePoint.chargePointSerialNumber);
    userActionRef.current = true;
  };

  useEffect(() => {
    if (userActionRef.current && serialNumberValid && !isLoading) {
      update({ chargePoint: chargePointData }).then(goToNextStep);
    }
  }, [serialNumberValid, isLoading]);

  useEffect(() => () => onSerialNumberChange(""), []);

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <ErrorFrame
        description={<StyledText type={Types.ns_16_sb} text={t("NOT_ALL_CONNECTORS_LISTED")} />}
      />
      <InfoFrame
        textType={Types.ns_16_m}
        description={
          <Flex gap={16} flexDirection="column">
            <span>
              <Trans
                i18nKey="CHECK_INFO_CORRECT_CONFIG"
                components={[
                  <StyledBlueLinkForTrans
                    key="CHECK_INFO"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(CONFIGURATION_MANUAL_URL_BY_LANGUAGE[getCurrentLanguage()])
                    }
                    target="_blank"
                  />
                ]}
                values={{ chargePointModel: onboardingData?.chargePoint?.model }}
              />
            </span>
            <List>
              <li>{t("HARD_RESET_MIGHT_BE_NEEDED")}</li>
              <li>{t("GO_AFTER_GROUP_CONFIG")}</li>
            </List>
          </Flex>
        }
      />
      <Button
        label={t("CONFIRM")}
        disabled={isLoading}
        useNunitoFont
        width="100%"
        margin={[16, 0, 0, 0]}
        onClick={getFreshChargePointHandler}
      />
    </Flex>
  );
};

export default ConnectorsGrouping;
