import React from "react";
import { Flex } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import ChargeBoxSerialNumber from "@/assets/images/chargepoint_serial_number.svg";
import ChargePointSerialNumber from "@/assets/images/chargebox_serial_number.svg";
import Model from "@/assets/images/chargepoint_model.svg";
import Vendor from "@/assets/images/chargepoint_vendor.svg";
import ConnectionType from "@/assets/images/connection_type.svg";
import FirmwareVersion from "@/assets/images/firmware_version.svg";
import DataTextFrame from "@/components/DataTextFrame";
import SuccessFrame from "@/components/SuccessFrame";
import { getHeaderTextByKey } from "@/utils/helpers";
import { IGetChargePointResponse } from "@/api/ApiTypes";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { useOnboarding } from "@/hooks/useOnboarding";

export const connectivityIconWithKey = {
  vendor: Vendor,
  model: Model,
  chargePointSerialNumber: ChargeBoxSerialNumber,
  chargeBoxSerialNumber: ChargePointSerialNumber,
  firmwareVersion: FirmwareVersion,
  connectionType: ConnectionType
} as const;

export const connectivityKeyMappings: Record<string, string> = {
  vendor: "CHARGE_POINT_VENDOR",
  model: "CHARGE_POINT_MODEL",
  chargePointSerialNumber: "CHARGE_POINT_SERIAL_NUMBER",
  chargeBoxSerialNumber: "CHARGE_BOX_SERIAL_NUMBER",
  firmwareVersion: "FIRMWARE_VERSION",
  connectionType: "CONNECTION_TYPE"
};

type ConnectivityIconKeyType = typeof connectivityIconWithKey;

const ConnectivityStatus: React.FC = () => {
  const { t } = useTranslation();
  const { data, goNextWithUpdate } = useOnboarding();

  const mapDataToChargePointData = (data: IGetChargePointResponse) => {
    return {
      vendor: data?.vendor || "-",
      model: data?.model || "-",
      chargePointSerialNumber: data?.chargePointSerialNumber || "-",
      chargeBoxSerialNumber: data?.chargeBoxSerialNumber || "-",
      firmwareVersion: data?.firmwareVersion || "-",
      connectionType: data?.connectionType || ("-" as null)
    };
  };

  const languageKeys: Array<keyof ConnectivityIconKeyType> = Object.keys(
    connectivityKeyMappings
  ) as Array<keyof ConnectivityIconKeyType>;

  const mappedData = mapDataToChargePointData(data?.chargePoint);

  const cpData = languageKeys.map((key) => {
    const { key: label } = getHeaderTextByKey(key, connectivityKeyMappings);
    return {
      label: t(label),
      value: mappedData[key],
      icon: connectivityIconWithKey[key]
    };
  });

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <SuccessFrame description={t("CHARGE_POINT_ONLINE_AND_CONNECTED")} />
      <DataTextFrame header={t("RECEIVED_CHARGE_POINT_DETAILS")} data={cpData} />
      <Button
        data-testid={"ConnectivityCheckButton"}
        label={t("CHECK_CONNECTORS")}
        useNunitoFont
        width="100%"
        margin={[32, 0, 0, 0]}
        onClick={goNextWithUpdate}
      />
    </Flex>
  );
};

export default ConnectivityStatus;
