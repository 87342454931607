import { useToast } from "@emonvia/web-components";
import { Navigate, Routes as Paths, Route, useNavigate } from "react-router-dom";
import InitialScreen from "@/containers/InitialScreen/InitialScreen";
import ConnectivityCheck from "@steps/ConnectivityCheck/ConnectivityCheck";
import ConnectivityStatus from "@steps/ConnectivityStatus/ConnectivityStatus";
import ConnectorsStatus from "@steps/ConnectorsStatus/ConnectorsStatus";
import InstallationReport from "@steps/InstallationReport/InstallationReport";
import AllRoutes, { globalNavigate, globalNotifyError } from "@/routing/AllRoutes";
import styled from "styled-components";
import ConnectivityGrid from "@steps/Troubleshooting/ConnectivityGrid";
import ConnectivityBe from "@steps/Troubleshooting/ConnectivityBe";
import ConnectivityComm from "@steps/Troubleshooting/ConnectivityComm";
import ConnectivityFw from "@steps/Troubleshooting/ConnectivityFw";
import ConnectivityReboot from "@steps/Troubleshooting/ConnectivityReboot";
import ConnectivityNotFound from "@steps/Troubleshooting/ConnectivityNotFound";
import ConnectivityDone from "@steps/Troubleshooting/ConnectivityDone";
import ConnectivityUnsuccessful from "@steps/Troubleshooting/ConnectivityUnsuccessful";
import ConnectivityOffline from "@steps/Troubleshooting/ConnectivityOffline";
import ConnectivityFwRetry from "@steps/Troubleshooting/ConnectivityFwRetry";
import ConnectorsAvailable from "@steps/Troubleshooting/ConnectorsAvailable";
import ConnectorsGrouping from "@steps/Troubleshooting/ConnectorsGrouping";
import AdditionalInfo from "@steps/AdditionalInfo/AdditionalInfo";
import ConnectorEvseID from "@steps/ConnectorEvseID/ConnectorEvseID";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";

const RedirectToStart = () => {
  const location = useLocation();
  const search = location.search;
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (redirect) {
    return <Navigate to={`${AllRoutes.CONFIG_WIZARD_ROOT}${search}`} replace />;
  }

  return null;
};

const StyledAppContainer = styled.div`
  position: relative;
  max-width: 495px;
  margin: auto;
  padding: 32px 20px 100px;
`;

const Routes = () => {
  const navigate = useNavigate();
  const { notifyError } = useToast();

  globalNavigate.current = navigate;
  globalNotifyError.current = notifyError;
  // TODO: will be uncommented after release
  return (
    <>
      <StyledAppContainer>
        <Paths>
          <Route path={AllRoutes.CONFIG_WIZARD_ROOT} element={<InitialScreen />} />
          <Route path={AllRoutes.CONNECTIVITY_CHECK} element={<ConnectivityCheck />} />
          <Route path={AllRoutes.CONNECTIVITY_STATUS} element={<ConnectivityStatus />} />
          <Route path={AllRoutes.CONNECTORS_STATUS} element={<ConnectorsStatus />} />
          <Route path={AllRoutes.CONNECTOR_EVSEID} element={<ConnectorEvseID />} />
          <Route path={AllRoutes.ADD_INFO} element={<AdditionalInfo />} />
          <Route path={AllRoutes.INSTALLATION_REPORT} element={<InstallationReport />} />
          {/*<Route path={AllRoutes.EMAIL_NEWSLETTER} element={<EmailNewsletter />} />*/}

          {/* TROUBLESHOOTING */}
          <Route path={AllRoutes.CONNECTIVITY_NO_FOUND} element={<ConnectivityNotFound />} />
          <Route
            path={AllRoutes.CONNECTIVITY_TROUBLESHOOT_GRID}
            element={<ConnectivityGrid steps={5} activeStep={0} />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_TROUBLESHOOT_BE}
            element={<ConnectivityBe steps={5} activeStep={1} />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_TROUBLESHOOT_COMM}
            element={<ConnectivityComm steps={5} activeStep={2} />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_TROUBLESHOOT_FW}
            element={<ConnectivityFw steps={5} activeStep={3} />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_TROUBLESHOOT_REBOOT}
            element={<ConnectivityReboot steps={5} activeStep={4} />}
          />
          <Route path={AllRoutes.CONNECTIVITY_TROUBLESHOOT_DONE} element={<ConnectivityDone />} />
          <Route
            path={AllRoutes.CONNECTIVITY_TROUBLESHOOT_UNSUCCESSFUL}
            element={<ConnectivityUnsuccessful />}
          />

          {/* TROUBLESHOOTING PRE-CONFIG */}
          <Route
            path={AllRoutes.CONNECTIVITY_PRE_CONFIG_OFFLINE}
            element={<ConnectivityOffline />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_FW_RETRY}
            element={<ConnectivityFwRetry />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_GRID}
            element={<ConnectivityGrid steps={4} activeStep={0} />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_BE}
            element={<ConnectivityBe steps={4} activeStep={1} />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_COMM}
            element={<ConnectivityComm steps={4} activeStep={2} />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_REBOOT}
            element={<ConnectivityReboot steps={4} activeStep={3} />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_DONE}
            element={<ConnectivityDone />}
          />
          <Route
            path={AllRoutes.CONNECTIVITY_PRE_CONFIG_TROUBLESHOOT_UNSUCCESSFUL}
            element={<ConnectivityUnsuccessful />}
          />

          {/* TROUBLESHOOTING CONNECTORS */}
          <Route
            path={AllRoutes.CONNECTORS_TROUBLESHOOT_GROUPING}
            element={<ConnectorsGrouping />}
          />
          <Route
            path={AllRoutes.CONNECTORS_TROUBLESHOOT_AVAILABLE}
            element={<ConnectorsAvailable />}
          />
          <Route
            path={AllRoutes.CONNECTORS_TROUBLESHOOT_FAILED}
            element={<ConnectivityUnsuccessful />}
          />

          {/* REDIRECT */}
          <Route path="*" element={<RedirectToStart />} />
        </Paths>
      </StyledAppContainer>
    </>
  );
};

export default Routes;
