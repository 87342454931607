import React from "react";
import { Flex } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import ElectricIcon from "@/assets/images/electricIcon.svg";
import GuideIcon from "@/assets/images/guideIcon.svg";
import ReportIcon from "@/assets/images/reportIcon.svg";
import GuideTextFrame from "@/components/GuideTextFrame";

const GuideFrame: React.FC = () => {
  const { t } = useTranslation();

  const guideInformation = [
    {
      header: t("QUICK_SETUP"),
      description: t("CONFIGURE_CHARGE_POINT_IN_LESS_THAN_MINUTE"),
      icon: ElectricIcon
    },
    {
      header: t("GUIDED_CONFIGURATION"),
      description: t("WE_WILL_GUID_YOU_THROUGH_CONFIGURATION"),
      icon: GuideIcon
    },
    {
      header: t("REPORT_AND_HANDOVER"),
      description: t("DOWNLOAD_THE_FINAL_REPORT"),
      icon: ReportIcon
    }
  ];

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      {guideInformation.map((info, index) => (
        <Flex
          flexDirection="column"
          style={{ maxWidth: "100%", width: "100%" }}
          key={`${info.header}-${index}`}
        >
          <GuideTextFrame
            header={info.header}
            description={info.description}
            sectionNumber={index + 1}
            icon={info.icon}
            margin={index > 0 ? [16, 0, 0, 0] : [0]}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default GuideFrame;
