import React from "react";
import { Flex, Types } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { AllRoutes } from "@/routing/AllRoutes";
import { useTranslation } from "@/utils/translations/Translator";
import ConnectorIcon from "@/assets/images/connector.svg";
import DataTextFrame from "@/components/DataTextFrame";
import SuccessFrame from "@/components/SuccessFrame";
import InfoFrame from "@/components/InfoFrame";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "@/hooks/useOnboarding";
import { arraySorter } from "@/utils/helpers";
import { IOnboardingConnector } from "@/api/ApiTypes";

const ConnectorsStatus: React.FC = () => {
  const { t } = useTranslation();
  const { data: onboardingData, goNextWithUpdate } = useOnboarding();
  const navigate = useNavigate();

  const connectors = onboardingData?.chargePoint?.connectors || [];

  const data = arraySorter(connectors, "connectorIdentifier").map(
    (connector: IOnboardingConnector) => {
      return {
        label: `${t("CONNECTOR")} ${connector.connectorIdentifier}`,
        value: connector.connectorIdentifier ? t("AVAILABLE") : t("NOT_AVAILABLE"),
        icon: ConnectorIcon
      };
    }
  );

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <SuccessFrame description={t("SUCCESS_ALL_CONNECTORS_ARE_CONNECTED")} />
      <DataTextFrame header={t("RECEIVED_CONNECTORS_DETAILS")} data={data} />
      <InfoFrame description={t("IS_THIS_CORRECT_CONNECTORS_COUNT")} textType={Types.ns_16_m} />
      <Button
        data-testid={"ConnectivityCheckButton"}
        label={t("YES_ALL_LISTED")}
        useNunitoFont
        width="100%"
        margin={[32, 0, 0, 0]}
        onClick={goNextWithUpdate}
      />
      <Button
        data-testid={"ConnectivityCheckButton"}
        label={t("NOT_ALL_LISTED")}
        onClick={() => navigate(AllRoutes.CONNECTORS_TROUBLESHOOT_GROUPING)}
        useNunitoFont
        look="secondary"
        width="100%"
      />
    </Flex>
  );
};

export default ConnectorsStatus;
