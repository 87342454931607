import { Flex, StyledText, Types } from "@emonvia/web-components";
import ProgressBar, { IProgressBar } from "@/components/ProgressBar/ProgressBar";
import GuideTextFrame from "@/components/GuideTextFrame";
import { useTranslation } from "@/utils/translations/Translator";
import InfoFrame from "@/components/InfoFrame";
import infoQuestionIcon from "@/assets/images/infoQuestionIcon.svg";
import settingsImage from "@/assets/images/illustration-settings.svg";
import CopyButtonWithText from "@/components/CopyButtonWithText/CopyButtonWithText";
import { FC } from "react";
import { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";

const ConnectivityBe: FC<IProgressBar> = (props) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={32}>
      <ProgressBar {...props} />
      <Flex justifyContent="center">
        <img width={92} height={82} src={settingsImage} />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <GuideTextFrame
          description={<StyledText text={t("CHECK_BACKEND_SETTINGS")} type={Types.ns_16_sb} />}
        />
        <InfoFrame
          icon={infoQuestionIcon}
          description={
            <Flex gap={16} flexDirection="column">
              <StyledText text={t("IS_WEBSOCKET_LINK_CORRECT")} type={Types.ns_16_m} />
              <Flex gap={8} flexDirection="column">
                <StyledText text={t("WEB_SOCKET_URL") + ":"} type={Types.ns_16_m} />
                <CopyButtonWithText text="wss://ocpp.reev.com" />
              </Flex>
            </Flex>
          }
        />
      </Flex>
      <Button
        data-testid="confirm-button"
        label={t("CONFIRM")}
        useNunitoFont
        width="100%"
        onClick={goToNextStep}
      />
    </Flex>
  );
};

export default ConnectivityBe;
