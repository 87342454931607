import React, { ReactNode } from "react";
import { Flex, Frame, StyledText, Types } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";

type IProps = {
  title?: string;
  subtitle?: string;
  description: ReactNode;
  icon?: string;
};

const ErrorFrame: React.FC<IProps> = ({ title, subtitle, description, icon }) => {
  return (
    <Frame
      data-testid={"Info_Frame"}
      border={{ borderRadius: "10px" }}
      style={{
        backgroundColor: theme.colors.infoErrorBackground,
        border: `1px solid ${theme.colors.infoErrorBorder}`,
        boxShadow: "none"
      }}
    >
      <Flex flexDirection="row" gap={12}>
        {icon && (
          <Flex flexDirection="column" gap={12} alignItems="flex-start" margin={[3, 0, 0, 0]}>
            <img src={icon} alt={`${icon} + img`} />
          </Flex>
        )}
        <Flex flexDirection="column" flex={1} gap={16}>
          {(title || subtitle) && (
            <div>
              {title && <StyledText text={title} type={Types.ns_16_b} color={theme.colors.red2} />}
              {subtitle && (
                <StyledText text={subtitle} type={Types.ns_16_sb} color={theme.colors.red2} />
              )}
            </div>
          )}
          <StyledText text={description} type={Types.ns_16_sb} color={theme.colors.grey750} />
        </Flex>
      </Flex>
    </Frame>
  );
};

export default ErrorFrame;
