import theme from "@/utils/theme/theme";
import { Flex, isNotEmpty, StyledText, Types } from "@emonvia/web-components";
import InfoFrame from "@/components/InfoFrame";
import ValidatedTextInput from "@/components/ValidatedTextInput";
import { Button, StyledBlueLinkForTrans } from "@/components/TrackedComponents/reevComponents";
import { Trans } from "react-i18next";
import { ACTIVATION_CODE } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";
import { useTranslation } from "@/utils/translations/Translator";
import useAdditionalInfoForm from "@/containers/Steps/AdditionalInfo/hook/useAdditionalInfoForm";

const AdditionalInfo = () => {
  const { t } = useTranslation();
  const {
    activationCode,
    maxLoad,
    errorMessage,
    activationCodeExists,
    maxLoadInvalid,
    handleActivationCodeChange,
    handleMaxLoadChange,
    goToNext,
    isLoading
  } = useAdditionalInfoForm();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <Flex
        padding={[20]}
        style={{
          backgroundColor: theme.colors.frameBodyBackground,
          borderRadius: "10px"
        }}
        flexDirection="column"
      >
        <Flex flexDirection="column" flex={1} gap={12}>
          <StyledText
            text={t("ENTER_ACTIVATION_CODE")}
            type={Types.ns_16_sb}
            color={theme.colors.primaryBlack}
          />
          <ValidatedTextInput
            data-testid="ChargePointInput_ActivationCodeInput"
            title={t("ACTIVATION_CODE")}
            placeholder={t("ENTER_8_DIGITS_ACTIVATION_CODE")}
            value={activationCode}
            onChange={handleActivationCodeChange}
            valid={activationCodeExists}
            error={activationCodeExists === false}
            validators={["removeSpaces"]}
            errorMessage={errorMessage && t(errorMessage)}
          />
        </Flex>
      </Flex>
      <InfoFrame
        description={
          <StyledText
            text={
              <>
                <StyledText
                  text={t("ACTIVATION_CODE_USED_BY_YOUR_CUSTOMER")}
                  style={{ marginBottom: 24 }}
                  type={Types.ns_16_m}
                />
                <Trans
                  i18nKey="YOU_CAN_FIND_ACTIVATION_CODE"
                  components={[
                    <StyledBlueLinkForTrans
                      key={"YOU_CAN_FIND_ACTIVATION_CODE" + "link"}
                      style={{ cursor: "pointer" }}
                      onClick={() => window.open(ACTIVATION_CODE[getCurrentLanguage()])}
                      target="_blank"
                    />
                  ]}
                />
              </>
            }
            type={Types.ns_16_m}
          />
        }
      />
      <Flex
        padding={[20]}
        style={{
          backgroundColor: theme.colors.frameBodyBackground,
          borderRadius: "10px",
          marginTop: 8
        }}
        flexDirection="column"
      >
        <Flex flexDirection="column" flex={1} gap={12}>
          <StyledText
            text={t("ENTER_THE_MAXIMUM_LOAD_KW")}
            type={Types.ns_16_sb}
            color={theme.colors.primaryBlack}
          />
          <ValidatedTextInput
            data-testid="ChargePointInput_MaxLoadInput"
            title={t("MAX_LOAD_IN_KW")}
            placeholder={t("ENTER_MAX_LOAD")}
            value={maxLoad}
            onChange={handleMaxLoadChange}
            validators={["keepOnlyNumbers", { key: "limit", options: { limit: 4 } }]}
            valid={isNotEmpty(maxLoad) && maxLoadInvalid === false}
            error={maxLoadInvalid}
            errorMessage={maxLoadInvalid && maxLoad != "" ? t("INVALID_VALUE") : null}
          />
        </Flex>
      </Flex>
      <InfoFrame
        description={
          <>
            <StyledText text={t("ENTER_THE_MAXIMUM_LOAD_ALLOWED_IN_KW")} type={Types.ns_16_m} />
            <StyledText
              text={t("THE_INFORMATION_HAS_NO_INFLUENCE_ON_THE_FUSED_LOAD")}
              style={{ marginTop: 24 }}
              type={Types.ns_16_m}
            />
          </>
        }
      />
      <Button
        label={t("CONFIRM")}
        useNunitoFont
        width="100%"
        margin={[32, 0, 0, 0]}
        disabled={activationCodeExists === false || maxLoadInvalid || isLoading}
        onClick={goToNext}
      />
    </Flex>
  );
};

export default AdditionalInfo;
