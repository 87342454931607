import styled from "styled-components";

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  padding: 28px 56px;
  position: relative;
  height: 106px;
  background: ${(props) => props.theme.colors.primaryBlack};
  z-index: ${(props) => props.theme.zIndex.pageHeader};
  box-sizing: border-box;
`;
