import React, { useEffect, useState } from "react";
import { Flex, Icon, IconTypes, StyledText, Types } from "@emonvia/web-components";
import ValidatedTextInput from "@/components/ValidatedTextInput";
import theme from "@/utils/theme/theme";
import { IOnboardingConnector } from "@/api/ApiTypes";
import { useTranslation } from "@/utils/translations/Translator";
import useEvseIdValidation from "@steps/ConnectorEvseID/hook/useEvseIdValidation";
import QrCodeScanner from "@/components/QRCodeScanner/QRCodeScanner";

interface IProps {
  connector: IOnboardingConnector;
  connectors: IOnboardingConnector[];
  onEvseIdChange: (index: number, newEvseId: string, isValid: boolean) => void;
  index: number;
}

const UpdateConnector: React.FC<IProps> = ({
  connector: { connectorIdentifier, evseId: savedEvseId },
  connectors,
  onEvseIdChange,
  index
}) => {
  const { t } = useTranslation();
  const [showQRModal, setShowQRModal] = useState<boolean>(false);
  const { evseId, errorMessage, handleChange, isValid, setErrorMessage } = useEvseIdValidation(
    savedEvseId || "",
    connectors
  );

  useEffect(() => {
    onEvseIdChange(index, evseId, isValid);
  }, [evseId, isValid, index]);

  const handleInputChange = (newEvseId: string) => {
    handleChange(newEvseId);
  };

  return (
    <Flex flexDirection="row" justifyContent="space-between" style={{ width: "100%" }}>
      <Flex flexDirection="column" width="100%" margin={[16, 0, 0]}>
        <StyledText
          data-testid="UpdateConnector_EvseInputLabel"
          text={`${t("CONNECTOR")} ${connectorIdentifier}`}
          type={Types.ns_16_l}
          color={theme.colors.primaryBlack}
          margin={[0, 0, 10]}
        />
        <ValidatedTextInput
          key={index}
          data-testid={`UpdateConnector_EvseIdInput${connectorIdentifier}`}
          value={evseId}
          onChange={handleInputChange}
          placeholder={t("SCAN_OR_ENTER_5_DIGIT_EVSEID")}
          validators={["removeSpaces"]}
          valid={!!evseId && !errorMessage}
          error={!!errorMessage}
          errorMessage={errorMessage && t(errorMessage)}
          cameraIcon={
            <Icon
              size={24}
              type={IconTypes.camera}
              style={{ marginRight: 12, color: theme.colors.blue500, cursor: "pointer" }}
              onClick={() => setShowQRModal(true)}
            />
          }
        />
        <QrCodeScanner
          key={connectorIdentifier}
          title={`${t("CONNECTOR")} ${connectorIdentifier}`}
          onChange={(val: string) => handleChange(val)}
          showModal={showQRModal}
          setShowModal={setShowQRModal}
          setErrorMessage={setErrorMessage}
        />
      </Flex>
    </Flex>
  );
};

export default UpdateConnector;
