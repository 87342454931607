import { Button } from "@emonvia/web-components";
import styled from "styled-components";

export const StyledSendEmailButton = styled(Button)`
  padding: 0 16px;
  border-radius: 0 10px 10px 0;
  border: 1px solid ${({ theme }) => theme.colors.grey500};
  box-shadow: none;
  background: ${(props) => props.classNames === "success" && props.theme.colors.success100};
  :focus {
    filter: unset !important;
  }
  :hover:not(:disabled) {
    filter: unset;
    background: ${({ theme }) => theme.colors.success100};
  }
  div {
    font-size: 14px;
    font-weight: 600 !important;
    color: ${(props) => props.classNames === "success" && props.theme.colors.success700};
  }
`;

export const StyledValidatedTextWrapper = styled.div<{ height: number }>`
  border-right: none;
  flex: 1;
  section {
    div {
      padding: 0;
      .inner {
        height: ${({ height }) => height}px;
        padding: 1px 5px 0 5px;
        border-radius: 10px 0 0 10px;
        border: 1px solid ${({ theme }) => theme.colors.grey500};
        border-right: none;
        input {
          font-size: 14px;
        }
        span {
          padding: inherit;
        }
      }
    }
  }
`;

export const StyledCheckMarkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
