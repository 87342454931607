import React from "react";
import { Flex, Frame, isNotEmpty, StyledText, Types } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";

export type DataProps = {
  label: string;
  value?: string;
  icon?: string;
};

type IProps = {
  header?: string;
  data: DataProps[];
  margin?: number[];
  width?: number | string;
};

const DataTextFrame: React.FC<IProps> = ({ header, data, margin, width }) => {
  return (
    <Frame
      data-testid={"Text_Frame"}
      border={{ borderRadius: "10px" }}
      margin={margin}
      style={{
        backgroundColor: theme.colors.frameBodyBackground,
        boxShadow: "none",
        width
      }}
    >
      <Flex gap={12} flexDirection="column">
        {isNotEmpty(header) && <StyledText text={`${header}`} type={Types.ns_16_l} />}
        {isNotEmpty(data) &&
          data.map((item: DataProps, index: number) => (
            <Flex
              key={index}
              flexDirection="row"
              gap={12}
              alignItems="center"
              style={{
                borderBottom: index !== data.length - 1 ? theme.borders.frameHeader : "none",
                paddingBottom: index !== data.length - 1 ? 16 : 0
              }}
            >
              <Flex flexDirection="column" alignItems="center">
                <img src={item?.icon} alt={`${item?.icon} + img`} />
              </Flex>
              <Flex flexDirection="column">
                <StyledText text={`${item?.value}`} type={Types.ns_16_b} />
                <StyledText text={item?.label} type={Types.ns_14_l} />
              </Flex>
            </Flex>
          ))}
      </Flex>
    </Frame>
  );
};

export default DataTextFrame;
