import { Flex, StyledText, Types } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import InfoFrame from "@/components/InfoFrame";
import ProgressBar, { IProgressBar } from "@/components/ProgressBar/ProgressBar";
import plugImage from "@/assets/images/illustration-plug.svg";
import GuideTextFrame from "@/components/GuideTextFrame";
import infoQuestionIcon from "@/assets/images/infoQuestionIcon.svg";
import { FC } from "react";
import { goToNextStep } from "@/routing/AllRoutes";
import { Button } from "@/components/TrackedComponents/reevComponents";

const ConnectivityGrid: FC<IProgressBar> = (props) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={32}>
      <ProgressBar {...props} />
      <Flex justifyContent="center">
        <img width={113} height={82} src={plugImage} />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <GuideTextFrame
          description={
            <>
              <StyledText text={t("RETRY_WAS_UNSUCCESSFUL")} type={Types.ns_16_sb} />
              <StyledText text={t("DOUBLE_CHECK_WIRES")} type={Types.ns_16_sb} />
            </>
          }
        />
        <InfoFrame
          icon={infoQuestionIcon}
          description={
            <StyledText
              text={t("IS_IT_POWERED_WITH_LIGHTS")}
              type={Types.ns_16_m}
              margin={[7, 0, 0]}
            />
          }
        />
      </Flex>
      <Button label={t("CONFIRM")} useNunitoFont width="100%" onClick={goToNextStep} />
    </Flex>
  );
};

export default ConnectivityGrid;
