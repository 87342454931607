import { StyledOrderedList } from "@/components/OrderedList/styles";
import { IdProvider, StyledText } from "@emonvia/web-components";
import { FC, ReactNode } from "react";
import theme from "@/utils/theme/theme";

type IProps = {
  items: (ReactNode | string)[];
};

const OrderedList: FC<IProps> = ({ items }) => {
  return (
    <StyledOrderedList>
      {items.map((item) => (
        <li key={IdProvider.stringId}>
          {typeof item === "string" ? (
            <StyledText style={{ fontWeight: 300 }} color={theme.colors.grey750}>
              {item}
            </StyledText>
          ) : (
            item
          )}
        </li>
      ))}
    </StyledOrderedList>
  );
};

export default OrderedList;
