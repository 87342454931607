import DefaultLanguageTranslations from "@/assets/locales/en.json";
import { isNotEmpty, isNotNullOrUndefined } from "@emonvia/web-components";
import { EVSEID_REGEXP } from "@/utils/regexp";

export type TransKey = keyof typeof DefaultLanguageTranslations;

export const getHeaderTextByKey = (key: string, keyMappings: Record<string, string>) => {
  const languageKey = keyMappings[key] || key;
  return { key: languageKey as TransKey, label: key };
};

export const arraySorter = <T,>(array: T[], key: keyof T): T[] => {
  if (isNotEmpty(array)) {
    return array.slice().sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }
  return [];
};

export const isUrl = (s: string) => {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(s);
};

export const mediaDownload = (
  fileName = "file.pdf",
  content: string,
  applicationType = "application/json"
) => {
  const blob = new Blob([content], { type: `${applicationType};charset=utf-8` });
  if (window.URL) {
    const url = window.URL.createObjectURL(blob);
    const downloadAnchorElement = document.createElement("a");
    downloadAnchorElement.href = url;
    downloadAnchorElement.download = fileName;

    document.body.appendChild(downloadAnchorElement);

    downloadAnchorElement.click();

    window.URL.revokeObjectURL(url);

    document.body.removeChild(downloadAnchorElement);
  } else {
    throw Error(`File download not supported by browser: ${window.navigator.userAgent}`);
  }
};

export const isEvseId = (value?: string): boolean => {
  return isNotNullOrUndefined(value) && EVSEID_REGEXP.test(value) && value.length === 5;
};
