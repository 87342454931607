import styled from "styled-components";

export const TooltipContainer = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  background-color: black;
  border-radius: 8px;
  padding: 10px;
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 250px;
  bottom: 26px;
  right: -26px;
`;

export const TooltipArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
  right: 0;
  bottom: 16px;
`;
