import React from "react";
import { Flex, StyledText, Types } from "@emonvia/web-components";
import { useTranslation } from "@/utils/translations/Translator";
import Checkbox from "@/components/Checkbox/Checkbox";
import { Trans } from "react-i18next";
import { StyledBlueLinkForTrans } from "@/components/TrackedComponents/reevComponents";
import { PRIVACY_POLICY_URL_BY_LANGUAGE } from "@/utils/constants";
import { getCurrentLanguage } from "@/utils/i18n";

type IProps = {
  agreeToEmail: boolean;
  agreeToNewsletter: boolean;
  setAgreeToEmail: (val: boolean) => void;
  setAgreeToNewsletter: (val: boolean) => void;
};

const ConsentCheckboxes: React.FC<IProps> = ({
  agreeToEmail,
  setAgreeToEmail,
  agreeToNewsletter,
  setAgreeToNewsletter
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" gap={16}>
      <Checkbox
        data-testid="I_AGREE_TO_RECEIVE_INSTALLATION_REPORT"
        description={
          <StyledText type={Types.ns_14_m} text={t("I_AGREE_TO_RECEIVE_INSTALLATION_REPORT")} />
        }
        tooltipText={
          <Trans
            i18nKey={"THE_DATA_WILL_BE_TRANSMITTED_STORED"}
            components={[
              <StyledBlueLinkForTrans
                key="PRIVACY_POLICY"
                style={{ cursor: "pointer" }}
                onClick={() => window.open(PRIVACY_POLICY_URL_BY_LANGUAGE[getCurrentLanguage()])}
                target="_blank"
              />
            ]}
          />
        }
        initialValue={agreeToEmail}
        onChange={setAgreeToEmail}
      />
      <Checkbox
        data-testid="I_WANT_STAY_UPTO_DATE_ON_PRODUCT_LAUNCH"
        description={
          <StyledText type={Types.ns_14_m} text={t("I_WANT_STAY_UPTO_DATE_ON_PRODUCT_LAUNCH")} />
        }
        tooltipText={
          <Trans
            i18nKey={"THE_DATA_WILL_BE_TRANSMITTED_STORED"}
            components={[
              <StyledBlueLinkForTrans
                key="PRIVACY_POLICY"
                style={{ cursor: "pointer" }}
                onClick={() => window.open(PRIVACY_POLICY_URL_BY_LANGUAGE[getCurrentLanguage()])}
                target="_blank"
              />
            ]}
          />
        }
        initialValue={agreeToNewsletter}
        onChange={setAgreeToNewsletter}
      />
    </Flex>
  );
};

export default ConsentCheckboxes;
