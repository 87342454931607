import { useState, useEffect, useRef } from "react";
import { useDebounce } from "@emonvia/web-components";
import { TransKey } from "@/utils/translations/Translator";
import ReevApiCalls from "@/api/ReevApiCalls";
import { IOnboardingConnector } from "@/api/ApiTypes";
import { isEvseId } from "@/utils/helpers";

const errorMessagesByStatus: { [key: number]: TransKey } = {
  412: "EVSEID_IS_ALREADY_ASSIGNED",
  404: "EVSEID_NOT_FOUND"
};

const useEvseIdValidation = (initialEvseId: string, connectors: IOnboardingConnector[]) => {
  const [evseId, setEvseId] = useState(initialEvseId);
  const [errorMessage, setErrorMessage] = useState<TransKey | "">("");
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const isVerifyingRef = useRef(false);

  const debouncedEvseId = useDebounce(evseId, 800);

  useEffect(() => {
    const validateEvseId = async () => {
      if (!debouncedEvseId) {
        setErrorMessage("");
        setIsValid(false);
        return;
      }

      const isValidFormat = isEvseId(debouncedEvseId);
      if (!isValidFormat) {
        setErrorMessage("INVALID_EVSEID");
        setIsValid(false);
        return;
      }

      const isDuplicate = connectors.filter((conn) => conn.evseId === debouncedEvseId).length > 1;
      if (isDuplicate) {
        setErrorMessage("DUPLICATE_EVSE_ID");
        setIsValid(false);
        return;
      }
      await verifyEvseId();
    };

    validateEvseId();
  }, [debouncedEvseId]);

  const verifyEvseId = async () => {
    if (!debouncedEvseId || isVerifyingRef.current) return;
    try {
      isVerifyingRef.current = true;
      await ReevApiCalls.getEvseByInfoById({ pathParams: { evseId: debouncedEvseId } });
      setIsValid(true);
      setErrorMessage("");
    } catch (error) {
      setIsValid(false);
      setErrorMessage(errorMessagesByStatus[error.status] || "UNKNOWN_ERROR");
    } finally {
      isVerifyingRef.current = false;
    }
  };

  const handleChange = (newEvseId: string) => {
    setEvseId(newEvseId);
  };

  return {
    evseId,
    errorMessage,
    setErrorMessage,
    handleChange,
    isValid
  };
};

export default useEvseIdValidation;
