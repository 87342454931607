import React, { ReactNode } from "react";
import { Flex, Frame, StyledText, Types } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";

type IProps = {
  description: ReactNode;
};

const SuccessFrame: React.FC<IProps> = ({ description }) => {
  return (
    <Frame
      data-testid={"Info_Frame"}
      border={{ borderRadius: "10px" }}
      style={{
        backgroundColor: theme.colorsWithOpacity.successBackground,
        boxShadow: "none",
        border: `1px solid ${theme.colorsWithOpacity.successBorder}`
      }}
    >
      <Flex flexDirection="row" gap={12}>
        <Flex flexDirection="column" flex={1}>
          <StyledText text={description} type={Types.ns_16_sb} color={theme.colors.grey750} />
        </Flex>
      </Flex>
    </Frame>
  );
};

export default SuccessFrame;
